const ServicesDetailsMetaData = [
    {
        id: 1,
        path: "Marketing Cloud",
        img: 'https://www.salesforce.com/content/dam/web/global/svg-icons/marketing-day.svg',
    },
    {
        id: 2,
        path: "Pardot",
        img: 'assets/servicesImages/05PardotAccount Engagement.png',
    },
    {
        id: 3,
        path: "Sales Cloud",
        img: "assets/servicesImages/04Sales cloud.png",
    },
    {
        id: 4,
        path: "Service Cloud",
        title: `<h3>Salesforce <span>Service Cloud</span><h3>`,
        img: "assets/servicesImages/03Service cloud.png",
    },
    {
        id: 5,
        path: "Experience Cloud",
        title: `<h3>Salesforce <span>Experience Cloud</span><h3>`,
        img: "assets/servicesImages/02Experience Cloud1.png",
    },


];


export default ServicesDetailsMetaData;