import React, { useState, useEffect } from "react";
import "../Components/css/otherservices.css";
import { Link } from "react-router-dom";

/*
*********************************************************************************************************
*  @Parent Component           :    
*  @React Component Name       :    
*  @Author                     :    <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*  @Company                    :    Antrazal
*  @Date                       :    05-07-2024
* 
*******************************************************************************************************
* Developer                                             TITLE
* <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)       other service section
********************************************************************************************************
*/

const OtherServices = () => {
	const [posts, setPosts] = useState([]);

	/*
	*********************************************************************************************************
	*   @React Component Name       :       OtherServices
	*   @Method                     :       fetchPosts
	*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
	*   @Company                    :       Antrazal
	*   @Description                :       Fetch the all service data from the Server 
	*********************************************************************************************************
	*/

	const fetchPosts = () => {
		fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/services`)
			.then((res) => res.json())
			.then((resData) => {
				setPosts(resData);
			})
			.catch((err) => {
				console.error('Error fetching blog posts:', err);
			});
	};

	useEffect(() => {
		fetchPosts();
	}, []);

	return (
		<div style={{ background: "rgb(16 18 64)", width: "100%", padding: " 2rem 1rem" }} >
			<div className="table_main_div">
				<div className="">
					<h2 className="heading" style={{ letterSpacing: "2px" }}>Other services that we offer </h2>
				</div>
				<div style={{ marginTop: "2rem", width: "100%" }} className="otherser_table">
					{
						posts.map((val, index) => {
							const { id, name } = val;
							return (
								<div key={id} style={{ padding: "5px" }}>
									<Link
										to={{
											pathname: "/services/",
											search: `?page=${name}`,
										}}
									>Salesforce {name}</Link>
								</div>
							)
						})
					}
				</div>
			</div>
		</div>
	);
};

export default OtherServices;
