import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
/*
*********************************************************************************************************
*  @Parent Component           :    App main page
*  @React Component Name       :    ScrollToTop
*  @Author                     :    <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*  @Company                    :    Antrazal
*  @Date                       :    05-07-2024
* 
*******************************************************************************************************
* Developer                                             TITLE
* <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)       Progressive add products LWC component development
********************************************************************************************************
*/
export default function ScrollToTop() {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname])

	return (
		null
	)
}
