import React from 'react'
import './css/loaderFormainPage.css'
export default function LoaderForMainSection(props) {
    return (
      
            <div className="containerForLoader2" style={{ display: props.loading ? 'block' : 'none' }}>
                <div className="flex2">
                    <div className="loader2"></div>
                </div>
            </div>
     
    )
}
