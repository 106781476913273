// import React, { useState, useEffect, useRef } from "react";
// import styled, { keyframes, css } from "styled-components";

// function CarouselLogo() {
//   const [posts, setPosts] = useState([]);
//   const logosSlideWrapperRef = useRef(null);
//   const logosSlideRef = useRef(null);

//   const fetchPosts = () => {
//     fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/testimonial`)
//       .then((res) => res.json())
//       .then((resData) => {
//         setPosts(resData);
//       })
//       .catch((err) => {
//         console.error('Error fetching blog posts:', err);
//       });
//   };

//   useEffect(() => {
//     fetchPosts();
//   }, []);
//   const duplicatedPosts = [...posts, ...posts];
//   return (
//     <AppContainer>
//       <Wrapper>
        
//         <Marquee>
//           <MarqueeGroup>
//             {duplicatedPosts.map((currentElement) => {
//               const { id, organisation_logo } = currentElement;
//               return (
//                 <ImageGroup key={id}>
//                   <Image src={organisation_logo} alt="Logo" />
//                 </ImageGroup>
//               );
//             })}
//           </MarqueeGroup>
//         </Marquee>
//       </Wrapper>
//     </AppContainer>
//   );
// }

// export default CarouselLogo;

// const AppContainer = styled.div`
//   color: #000000;
//   height: 25vh;
//   position: relative;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   @media (max-width: 780px) {
//     height: 15vh;
//   }
// `;

// const Wrapper = styled.div`
//   overflow: hidden;
//   width: 100%;
//   height: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   flex-direction: column;

//   @media (max-width: 580px) {
//     height: 100%;
//   }
// `;

// const Text = styled.div`
//   font-size: 35px;
//   font-weight: 500;
//   margin-bottom: 10px;
//   color: #02203c;
// `;
// const Marquee = styled.div`
//   display: flex;
//   width: 1200px;
//   overflow: hidden;
//   user-select: none;


// `;
// const Note = styled.div`
//   font-size: 18px;
//   font-weight: 200;
//   margin-bottom: 40px;
//   color: #7c8e9a;
// `;

// const Logos = styled.div`
//   overflow: hidden;
//   width: 100%;
//   height: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   position: relative;
// `;

// const LogosSlideWrapper = styled.div`
//   display: flex;
//   width: 100%;
//   white-space: nowrap;
//   transition: transform 10s linear;
// `;

// const LogosSlide = styled.div`
//   display: flex;
//   align-items: center;
//   white-space: nowrap;
// `;

// const ImageGroup = styled.div`
//   display: grid;
//   place-items: center;
//   // width: clamp(10rem, 1rem + 40vmin, 30rem);
//   // padding: calc(clamp(10rem, 1rem + 30vmin, 30rem) / 10);
// `;

// const Image = styled.img`
//   height: 50px;
//   margin: 0 30px;
//   max-width: 225px;
//   @media (max-width: 580px) {
//    height: 40px;
//     margin: 0 30px;
//     max-width: 225px !important;
//   }
// `;

// const scrollX = keyframes`
//   from {
//     transform: translateX(0);
//   }
//   to {
//     transform: translateX(-100%);
//   }
// `;

// const common = css`
//   flex-shrink: 0;
//   display: flex;
//   align-items: center;
//   justify-content: space-around;
//   white-space: nowrap;
//   width: 100%;
//   animation: ${scrollX} 10s linear infinite;
// `;

// const MarqueeGroup = styled.div`
//   ${common}
// `;


import React, { useState, useEffect } from "react";
import styled, { keyframes, css } from "styled-components";

function CarouselLogo() {
  const [posts, setPosts] = useState([]);

  const fetchPosts = () => {
    fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/testimonial`)
      .then((res) => res.json())
      .then((resData) => {
        setPosts(resData);
      })
      .catch((err) => {
        console.error('Error fetching blog posts:', err);
      });
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  const duplicatedPosts = [...posts, ...posts]; // Duplicate logos for smooth scrolling

  return (
    <div className="container">

  
    <AppContainer>
      <Wrapper>
        <Marquee>
          <MarqueeGroup>
            {duplicatedPosts.map((currentElement, index) => {
              const { id, organisation_logo } = currentElement;
              return (
                <ImageGroup key={id ? id : `logo-${index}`}>
                  <Image src={organisation_logo} alt="Logo" />
                </ImageGroup>
              );
            })}
          </MarqueeGroup>
        </Marquee>
      </Wrapper>
    </AppContainer>
    </div>
  );
}

export default CarouselLogo;

const AppContainer = styled.div`
  color: #000000;
  height: 25vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 780px) {
    height: 15vh;
  }
`;

const Wrapper = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 580px) {
    height: 100%;
  }
`;

const Marquee = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  user-select: none;
    will-change: transform;
`;

const scrollX = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%); /* Adjust to scroll halfway, then loop */
  }
`;

const common = css`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  white-space: nowrap;
  animation: ${scrollX} 10s linear infinite;
  will-change: transform;
`;

const MarqueeGroup = styled.div`
  ${common}
`;

const ImageGroup = styled.div`
  display: grid;
  place-items: center;
`;

const Image = styled.img`
  height: 50px;
  margin: 0 30px;
  max-width: 225px;

  @media (max-width: 580px) {
    height: 40px;
    margin: 0 20px;
  }
`;
