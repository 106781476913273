import React, { useEffect, useState } from 'react'
import './../Components/css/blog.css'
import OtherServices from '../Components/OtherServices';
import SetMeeting from '../Components/SetMeeting'
import CarouselLogo from '../Components/CarouselLogo';
import { Link } from 'react-router-dom';

/*
*********************************************************************************************************
*  @Parent Component           :    App home
*  @React Component Name       :    Blog
*  @Author                     :    <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*  @Company                    :    Antrazal
*  @Date                       :    05-07-2024
* 
*******************************************************************************************************
* Developer                                             TITLE
* <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)       Blog main page
********************************************************************************************************
*/

export default function Blog() {
	const [posts, setPosts] = useState([]);

	/*
	*********************************************************************************************************
	*   @React Component Name       :       Blog
	*   @Method                     :       useEffect
	*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
	*   @Company                    :       Antrazal
	*   @Description                :       render the data on changes or lend on the page 
	*********************************************************************************************************
	*/

	useEffect(() => {
		fetchPosts();
	}, []);

	/*
*********************************************************************************************************
*   @React Component Name       :       Blog
*   @Method                     :       fetchPosts
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       fetch the data from the server  
*********************************************************************************************************
*/

	const fetchPosts = () => {
		fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/blog`)
			.then((res) => res.json())
			.then((resData) => {
				
				setPosts(resData);
			})
			.catch((err) => {
				console.error('Error fetching blog posts:', err);

			});
	};
	const formateDate = (oldDate) => {
		const date = new Date(oldDate);
		const day = String(date.getDate()).padStart(2, '0');
		const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

		const month = months[date.getMonth()];
		const year = String(date.getFullYear()).slice(-2); // Get the last 2 digits of the year
		const formattedDate = `${day}-${month}-${year}`;
		return formattedDate
	}


	return (
		<>
			<div className='container blog-page'>
				<h1 className='blog-page-heading'>Latest  Blogs </h1>
				<div className='blog-main-page-grid'>
					{/* {
						posts.map((metadata) => (
							<Link to={{
								pathname: "/blog/page",
								search: `?viewmore=${metadata.title}`,
							}} className='blog-anchortag'>
								<div className='blog-card-main-div'>
									<div className='blog-image-div'><img src={metadata.image} alt="" /></div>
									<div className='blog-card-detail-div'>
										<h1 className='para-limit1'>{metadata.title}</h1>
										<p className='blog-card-author-name'>{metadata.authorName}</p>
										<p className='blog-card-creating-date'>{metadata.created_date}</p>
									</div>
								</div>
							</Link>
						))
					} */}
					{
						posts.map((metadata) => (
							<Link to={{
								pathname: "/blog/page",
								search: `?viewmore=${metadata.title}`,
							}} className='blog-anchortag'>
								<div className="blogCard">
									<div className="image-container">
										<img src={metadata.image} alt={metadata.title} />
									</div>
									<h2>{metadata.title}</h2>
									<p className='blogCardPara'>{metadata.shotDescription}</p>
									<div className='blogCardAutherDiv'>
										<p className='blog-card-author-name'>{metadata.authorName}</p>
										<p className='blog-card-creating-date'>{formateDate(metadata.created_date)}</p>
									</div>
									
								</div>
							</Link>
						))
					}

				</div>
			</div>
			<SetMeeting />
			<OtherServices />
		</>
	)
}
