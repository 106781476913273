import React from "react";
import { Container } from "react-bootstrap";
import "../Components/css/areaExpertise.css";

function AreasExpertise(props) {

	return (
		<>
			<section style={{ background: "#f6f8fe" }} className="">
				<Container>
					<div style={{ padding: "3rem 1rem" }} className="">
						<div className="">
							<h1 className="heading heading_area" style={{ textAlign: "end" }}>
								Areas of our expertise
							</h1>
						</div>
						<div  className="services-area-expertise-grid">
							{props.AreasExpertise.map((item) => (
								
									<div className="sarea" key={item.id}>
										<h3>{item.name}</h3>
									</div>
							))}
						</div>
					</div>
				</Container>
			</section>
		</>
	);
}
export default AreasExpertise;