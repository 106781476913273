import React from 'react'

export default function SmallLoader() {
  return (
    <div>
        <div className="">
                <div className="smallLoader"></div>
            </div>
    </div>
  )
}
