import React, { Component } from 'react';
import Input from "../../components/Form/Input/Input";
import Button from "../../components/Button/Button";
import Auth from '../Auth/Auth';
import FilePicker from '../../components/Form/Input/FilePicker';
import Loader from '../../components/Loader/Loader';
import Post from '../../components/Feed/Post/Post';
import Multiselect from 'multiselect-react-dropdown';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import ImageUrlGenerator from './GetPublicUrlOfImages';
import TextEditorForAdminPanel from './TextEditorForAdminPanel';

/*
*********************************************************************************************************
*  @Parent Component           :    Admin panel 
*  @React Component Name       :    Service
*  @Author                     :    <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*  @Company                    :    Antrazal
*  @Date                       :    04-07-2024
* 
*******************************************************************************************************
* Developer                                             TITLE
* <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)       Progressive add products LWC component development
********************************************************************************************************
*/

export default class Service extends Component {

/*
*********************************************************************************************************
*   @React Component Name       :       Service
*   @Method                     :       state
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       this initialize the variable 
*********************************************************************************************************
*/

    state = {
        editorState: EditorState.createEmpty(),
        serviceForm: {
            name: {
                value: '',
                valid: false,
                validators: [],
            },
            image: {
                value: '',
                valid: false,
                validators: [],
            },
            content: {
                value: '',
                valid: false,
                validators: [],
            },
            serviceIcon: {
                value: '',
                valid: false,
                validators: [],
            },
            shotDescription: {
                value: '',
                valid: false,
                validators: [],
            },
            textEditorContent: {
                value: '',
                valid: false,
                validators: [],
            },

            formIsValid: false,

        },
        editPostBtn: false,
        editPostId: null,
        formShow: false,
        posts: [],
        caseStudy: [],
        selectedCaseStudy: [],
        blog: [],
        selectedBlog: [],
        expertise: [],
        selectedExpertise: [],
        postsLoading: false,
    }; 

/*
*********************************************************************************************************
*   @React Component Name       :       Service
*   @Method                     :       inputChangeHandler
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       this method vlalidates for make changes on the input 
*********************************************************************************************************
*/

    inputChangeHandler = (input, value) => {
        this.setState(prevState => {
            let isValid = true;
            if (Array.isArray(prevState.serviceForm[input].validators)) {
                for (const validator of prevState.serviceForm[input].validators) {
                    isValid = isValid && validator(value);
                }
            } else {
                isValid = false;
            }
            const updatedForm = {
                ...prevState.serviceForm,
                [input]: {
                    ...prevState.serviceForm[input],
                    valid: isValid,
                    value: value
                }
            };
            let formIsValid = true;
            for (const inputName in updatedForm) {
                formIsValid = formIsValid && updatedForm[inputName].valid;
            }
            return {
                serviceForm: updatedForm,
                formIsValid: formIsValid
            };
        });
    };

/*
*********************************************************************************************************
*   @React Component Name       :       Service
*   @Method                     :       inputBlurHandler
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       this method keep track previous input data
*********************************************************************************************************
*/

    inputBlurHandler = input => {
        this.setState(prevState => {
            return {
                serviceForm: {
                    ...prevState.serviceForm,
                    [input]: {
                        ...prevState.serviceForm[input],
                        touched: true,
                    },
                },
            };
        });
    };

/*
*********************************************************************************************************
*   @React Component Name       :       Service
*   @Method                     :       toggleForm
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       this method toogle the form
*********************************************************************************************************
*/

    toggleForm = () => {
        this.setState(prevState => ({
            formShow: !prevState.formShow,
        }));
    };

/*
*********************************************************************************************************
*   @React Component Name       :       Service
*   @Method                     :       postInputChangeHandler
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       this method using for the uploade the images in s3 bucket
*********************************************************************************************************
*/

    postInputChangeHandler = async (input, value, files) => {
        if (files && files.length > 0) {
            const formData = new FormData();
            formData.append('file', files[0]);
            try {
                const response = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/upload`, {
                    method: 'POST',
                    body: formData,
                });
                if (response.ok) {
                    const { url } = await response.json();
                    const fieldToUpdate = input === 'image' ? 'image' : 'image';
                    this.setState(prevState => ({
                        serviceForm: {
                            ...prevState.serviceForm,
                            [fieldToUpdate]: {
                                ...prevState.serviceForm[fieldToUpdate],
                                valid: true,
                                value: url,
                            },
                        },
                    }));
                } else {
                    console.error('Failed to upload image');
                }
            } catch (error) {
                console.error('Error uploading image:', error);
            }
        }
    };

/*
*********************************************************************************************************
*   @React Component Name       :       Service
*   @Method                     :       setNullAllinputField
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       set the all input value nulll 
*********************************************************************************************************
*/

    setNullAllinputField() {
        this.state.serviceForm.name = ''
        this.state.serviceForm.image = ''
        this.state.serviceForm.content = ''
        this.state.serviceForm.shotDescription = ''
        this.state.serviceForm.serviceIcon = ''
        this.state.serviceForm.textEditorContent = ''
    }

/*
*********************************************************************************************************
*   @React Component Name       :       Service
*   @Method                     :       onService
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       this method take all input data and store in the server  or update the created service
*********************************************************************************************************
*/

    onService = event => {
        event.preventDefault();
        const {
            name,
            image,
            content,
            shotDescription,
            serviceIcon,
            textEditorContent
        } = this.state.serviceForm;
        const requestData = {
            name: name.value,
            image: image.value,
            content: content.value,
            shotDescription: shotDescription.value,
            serviceIcon: serviceIcon.value,
            textEditorContent: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())),
            createdBy: localStorage.getItem('userId'),
            updatedBy: localStorage.getItem('userId'),
            CaseStudyId: this.state.selectedCaseStudy.map((caseStudy) => caseStudy.id),
            BlogId: this.state.selectedBlog.map((blog) => blog.id),
            AreaOfExpertiseId: this.state.selectedExpertise.map((expertise) => expertise.id)
        };
        if (this.state.editPostBtn) {
            fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/services/` + this.state.editPostId, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            })
                .then(res => res.json())
                .then(data => {
                    this.toggleForm();
                    this.fetchPosts();
                    this.setNullAllinputField();
                    this.setState({})
                    this.setState(prevState => ({
                        editPostBtn: false,
                        editPostId: null,
                    }));
                    this.setState({
                        selectedCaseStudy: [],
                        selectedBlog: [],
                        selectedExpertise: []
                    })
                })
                .catch(err => {
                    console.error('Error updating testimonial:', err);
                });
        }
        else {
            fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/services`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            })
                .then(res => { return res })
                .then(data => {
                    if (data.status === 500) {
                        alert("Internal server error ")
                    }
                    this.toggleForm();
                    this.fetchPosts();
                    this.setNullAllinputField()
                    this.setState({
                        selectedCaseStudy: [],
                        selectedBlog: [],
                        selectedExpertise: []
                    })
                })
                .catch(err => {
                    console.error('Error creating testimonial:', err);
                });
        }
    };

/*
*********************************************************************************************************
*   @React Component Name       :       Service
*   @Method                     :       componentDidMount
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       fetch the data initialiy and call when it render 
*********************************************************************************************************
*/

    componentDidMount() {
        this.fetchPosts();
        this.getBlogData()
        this.getCaseStudyData()
        this.getAreaOfExpertiseData()
    }

/*
*********************************************************************************************************
*   @React Component Name       :       Service
*   @Method                     :       getBlogData
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       this api get the blog data 
*********************************************************************************************************
*/

    getBlogData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/blog`);
            if (!response.ok) {
                throw new Error('Failed to fetch Service data');
            }
            const data = await response.json();
            const blogData = data.map((blog) => ({
                id: blog.id,
                title: blog.title,
            }));
            this.setState({ blog: blogData });
        } catch (error) {
            console.error('Error fetching country data:', error);
        }
    };

    /*
*********************************************************************************************************
*   @React Component Name       :       Service
*   @Method                     :       getAreaOfExpertiseData
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       this api get areaOfExperties data
*********************************************************************************************************
*/

    getAreaOfExpertiseData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/areaofexpertise`);
            if (!response.ok) {
                throw new Error('Failed to fetch Service data');
            }
            const data = await response.json();
            const expertiseData = data.map((expertise) => ({
                id: expertise.id,
                name: expertise.name,
            }));
            this.setState({ expertise: expertiseData });
        } catch (error) {
            console.error('Error fetching country data:', error);
        }
    };

/*
*********************************************************************************************************
*   @React Component Name       :       Service
*   @Method                     :       getCaseStudyData
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       this api get CaseStudy data
*********************************************************************************************************
*/

    getCaseStudyData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/caseStudy`);
            if (!response.ok) {
                throw new Error('Failed to fetch Service data');
            }
            const data = await response.json();
            const caseStudyData = data.map((casestudy) => ({
                id: casestudy.id,
                name: casestudy.name,
            }));
            this.setState({ caseStudy: caseStudyData });
        } catch (error) {
            console.error('Error fetching country data:', error);
        }
    };

/*
*********************************************************************************************************
*   @React Component Name       :       Service
*   @Method                     :       fetchPosts
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       this api fetch the service data for render 
*********************************************************************************************************
*/

    fetchPosts = () => {
        this.setState({ postsLoading: true });
        fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/services`)
            .then(res => {
                if (res.status !== 200) {
                    throw new Error('Failed to fetch services.');
                }
                return res.json();
            })
            .then(resData => {
                this.setState({ status: resData.status, posts: resData, postsLoading: false });
            })
            .catch(this.catchError);
    };

/*
*********************************************************************************************************
*   @React Component Name       :       Service
*   @Method                     :       onEditorStateChange
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       this method set the state of the editState on changes on the BlogEditor 
*********************************************************************************************************
*/

    onEditorStateChange = (newEditorState) => {
        const textEditorContent = draftToHtml(convertToRaw(newEditorState.getCurrentContent()));
        this.setState((prevState) => ({
            editorState: newEditorState,
            serviceForm: {
                ...prevState.serviceForm,
                textEditorContent: {
                    ...prevState.serviceForm.textEditorContent,
                    value: textEditorContent,
                    valid: true,
                },
            },
        }));
    };

/*
*********************************************************************************************************
*   @React Component Name       :       Service
*   @Method                     :       deletePostHandler
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       this methode delete the service 
*********************************************************************************************************
*/

    deletePostHandler = postId => {
        this.setState({ postsLoading: true });
        fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/services/` + postId, {
            method: "DELETE",
            headers: {
                Authorization: 'Bearer ' + this.props.token,
            },
        })
            .then(res => {
                if (res.status !== 200 && res.status !== 201) {
                    throw new Error('Deleting a post failed!');
                }
           
                this.fetchPosts();
                return res.json();
            })
            .catch(err => {
                console.log(err);
                this.setState({ postsLoading: false });
            });
    };

/*
*********************************************************************************************************
*   @React Component Name       :       Service
*   @Method                     :       fetchSinglePost
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       this method fetch single servcie data for the servcie id and render all data in the input field for update 
*********************************************************************************************************
*/

    fetchSinglePost = postId => {
        this.setState({ postsLoading: true });
        fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/services/` + postId)
            .then(res => {
                if (res.status !== 200) {
                    throw new Error('Failed to fetch testimonials.');
                }
                return res.json();
            })
            .then(resData => {
                const contentBlock = htmlToDraft(resData.content || '<p>Your initial content.</p>');
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const arrayBlog = resData.blog.map(blog => ({
                    id: blog.id,
                    title: blog.title
                }))
                const arrayCaseStudy = resData.caseStudy.map(blog => ({
                    id: blog.id,
                    name: blog.name
                }))
                const arrayExpertise = resData.areaOfExpertises.map(expertise => ({
                    id: expertise.id,
                    name: expertise.name
                }))
                const selectedBlog = arrayBlog.map((blog) => blog.id)
                const selectedCaseStudy = arrayCaseStudy.map((caseStudy) => caseStudy.id)
                const selectedExpertise = arrayExpertise.map((expertise) => expertise.id)
                this.setState(prevState => ({
                    serviceForm: {
                        ...prevState.serviceForm,
                        name: {
                            ...prevState.serviceForm.name,
                            value: resData.name
                        },
                        image: {
                            ...prevState.serviceForm.image,
                            value: resData.image,
                            valid: true,
                        },
                        content: {
                            ...prevState.serviceForm.content,
                            value: resData.content,
                            valid: true,
                        },
                        shotDescription: {
                            ...prevState.serviceForm.shotDescription,
                            value: resData.shotDescription
                        },
                        serviceIcon: {
                            ...prevState.serviceForm.serviceIcon,
                            value: resData.serviceIcon
                        },
                        textEditorContent: {
                            ...prevState.serviceForm.textEditorContent,
                            value: resData.textEditorContent,
                            valid: true,
                        },
                    },
                    editorState: EditorState.createWithContent(contentState),
                    selectedBlog: selectedBlog,
                    selectedCaseStudy: selectedCaseStudy,
                    selectedExpertise: selectedExpertise
                }))
                this.handleSelectForBlog(resData.blog)
                this.handleRemoveForBlog(resData.blog)
                this.handleSelectForCaseStudy(resData.caseStudy)
                this.handleRemoveForCaseStudy(resData.caseStudy)
                this.handleSelectForExpertise(resData.areaOfExpertises)
                this.handleRemoveForExpertise(resData.areaOfExpertises)
            })
            .catch(error => {
                console.error('Error fetching testimonial:', error);
            });
    };

/*
*********************************************************************************************************
*   @React Component Name       :       Service
*   @Method                     :       editPost
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       this method set the ediot mode to service and render all data on the input ield 
*********************************************************************************************************
*/

    editPost = postId => {
        this.setState({
            editPostBtn: true,
            editPostId: postId
        }, () => {
            this.fetchSinglePost(postId);
            this.toggleForm();
        });
    };

/*
*********************************************************************************************************
*   @React Component Name       :       Service
*   @Method                     :       handleSelectForBlog
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       this method help to select the blog related to the service 
*********************************************************************************************************
*/

    handleSelectForBlog = (selectedList, selectedItem) => {
        const selectedBlog = selectedList.map((blog) => ({
            id: blog.id,
            title: blog.title,
        }));
        this.setState({ selectedBlog: selectedBlog });
    };

/*
*********************************************************************************************************
*   @React Component Name       :       Service
*   @Method                     :       handleRemoveForBlog
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       this method use for the remove the blog 
*********************************************************************************************************
*/

    handleRemoveForBlog = (selectedList, selectedItem) => {
        if (!selectedItem) {
            return;
        }
        const updatedBlog = this.state.selectedBlog.filter(
            (blog) => blog.id !== selectedItem.id
        );
        this.setState({ selectedBlog: updatedBlog });
    };

/*
*********************************************************************************************************
*   @React Component Name       :       Service
*   @Method                     :       handleSelectForCaseStudy
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       this method help to slect the CaseStudy related to the service 
*********************************************************************************************************
*/

    handleSelectForCaseStudy = (selectedList, selectedItem) => {
        const selectedCaseStudy = selectedList.map((caseStudy) => ({
            id: caseStudy.id,
            name: caseStudy.name,
        }));
        this.setState({ selectedCaseStudy: selectedCaseStudy });

    };

/*
*********************************************************************************************************
*   @React Component Name       :       Service
*   @Method                     :       handleRemoveForCaseStudy
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       this method help to remove the CaseStudy related to the service 
*********************************************************************************************************
*/

    handleRemoveForCaseStudy = (selectedList, selectedItem) => {
        if (!selectedItem) {
            console.warn('selectedItem is undefined in handleRemove');
            return;
        }
        const updatedCaseStudy = this.state.selectedCaseStudy.filter(
            (caseStudy) => caseStudy.id !== selectedItem.id
        );
        this.setState({ selectedCaseStudy: updatedCaseStudy });
    };

/*
*********************************************************************************************************
*   @React Component Name       :       Service
*   @Method                     :       handleSelectForExpertise
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       this method help to slect the areaOfxperties related to the service 
*********************************************************************************************************
*/

    handleSelectForExpertise = (selectedList, selectedItem) => {
        const selectedExpertise = selectedList.map((expertise) => ({
            id: expertise.id,
            name: expertise.name,
        }));
        this.setState({ selectedExpertise: selectedExpertise });
    }

/*
*********************************************************************************************************
*   @React Component Name       :       Service
*   @Method                     :       handleRemoveForExpertise
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       this method help to remove the Experties related to the service 
*********************************************************************************************************
*/

    handleRemoveForExpertise = (selectedList, selectedItem) => {
        if (!selectedItem) {
            return;
        }
        const updatedexpertise = this.state.selectedExpertise.filter(
            (expertise) => expertise.id !== selectedItem.id
        );
        this.setState({ selectedExpertise: updatedexpertise });
    };
    handleTextEditorContentChange = (content) => {
        this.setState(prevState => ({
            serviceForm: {
                ...prevState.serviceForm,
                content: {
                    ...prevState.serviceForm.content,
                     value:content
                }
            }
        }));
    };

    render() {
        const { editorState } = this.state;
        return (
            <div className="main-div-login">
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: '1rem 0 ',
                    }}
                >
                    <button onClick={this.toggleForm} className='buttonCreatePost'>{this.state.formShow ? ("Close New Post") : ("Create New Post")}</button>
                </div>
                <ImageUrlGenerator />
                {this.state.formShow && (
                    <Auth>
                        <form onSubmit={this.onService}>
                            <Input
                                id="name"
                                label="Service Name"
                                type="text"
                                control="input"
                                onChange={this.inputChangeHandler}
                                value={this.state.serviceForm['name'].value}
                                valid={this.state.serviceForm['name'].valid}
                                touched={this.state.serviceForm['name'].touched}
                            />
                            <Input
                                id="serviceIcon"
                                label="Service serviceIcon"
                                type="text"
                                control="input"
                                onChange={this.inputChangeHandler}
                                value={this.state.serviceForm['serviceIcon'].value}
                                valid={this.state.serviceForm['serviceIcon'].valid}
                                touched={this.state.serviceForm['serviceIcon'].touched}
                            />
                            <Input
                                id="shotDescription"
                                label="Service shotDescription"
                                // type="text"
                                control="textarea"
                                onChange={this.inputChangeHandler}
                                value={this.state.serviceForm['shotDescription'].value}
                                valid={this.state.serviceForm['shotDescription'].valid}
                                touched={this.state.serviceForm['shotDescription'].touched}
                            />
                            <FilePicker
                                id="image"
                                label="Service image"
                                control="input"
                                onChange={this.postInputChangeHandler}
                                value={this.state.serviceForm['image'].value}
                                valid={this.state.serviceForm['image'].valid}
                                touched={this.state.serviceForm['image'].touched}
                                onBlur={this.inputBlurHandler.bind(this, 'image')}
                            />
                            <Input
                                id="content"
                                label="Service content"
                                control="textarea"
                                rows="5"
                                onChange={this.inputChangeHandler}
                                valid={this.state.serviceForm['content'].valid}
                                touched={this.state.serviceForm['content'].touched}
                                value={this.state.serviceForm['content'].value}
                            />
                            <form className="row g-3" method="post" style={{ margin: '1rem 0' }}>
                                <div className="">
                                    <label className="form-label">Select Blog</label>
                                    <div className="text-dark">
                                        <Multiselect
                                            isObject
                                            onSelect={this.handleSelectForBlog}
                                            options={this.state.blog}
                                            onRemove={this.handleRemoveForBlog}
                                            displayValue="title"
                                            showCheckbox={true}
                                            selectedValues={this.state.selectedBlog}
                                        />
                                    </div>
                                </div>
                            </form>
                            <form className="row g-3" method="post" style={{ margin: '1rem 0' }}>
                                <div className="">
                                    <label className="form-label">Select CaseStudy</label>
                                    <div className="text-dark">
                                        <Multiselect
                                            isObject
                                            onSelect={this.handleSelectForCaseStudy}
                                            options={this.state.caseStudy}
                                            onRemove={this.handleRemoveForCaseStudy}
                                            displayValue="name"
                                            showCheckbox={true}
                                            selectedValues={this.state.selectedCaseStudy}
                                        />
                                    </div>
                                </div>
                            </form>
                            <form className="row g-3" method="post" style={{ margin: '1rem 0' }}>
                                <div className="">
                                    <label className="form-label">Select Area of expertise</label>
                                    <div className="text-dark">
                                        <Multiselect
                                            isObject
                                            onSelect={this.handleSelectForExpertise}
                                            options={this.state.expertise}
                                            onRemove={this.handleRemoveForExpertise}
                                            displayValue="name"
                                            showCheckbox={true}
                                            selectedValues={this.state.selectedExpertise}
                                        />
                                    </div>
                                </div>
                            </form>
                            {/* <Editor
                                editorState={editorState}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={this.onEditorStateChange}
                            /> */}
                            <TextEditorForAdminPanel  onContentChange={this.handleTextEditorContentChange} initialContent={this.state.serviceForm.content.value} />
                            {this.state.editPostBtn ? (
                                <Button design="raised" type="submit" loading={this.props.loading}>
                                    Update Post
                                </Button>
                            ) : (
                                <Button design="raised" type="submit" loading={this.props.loading}>
                                    Create Post
                                </Button>
                            )}
                        </form>
                    </Auth>
                )}
                <Auth>
                    {this.state.postsLoading && <Loader />}
                    {this.state.posts.map(post => (
                        <Post
                            key={post.id}
                            id={post.id}
                            title={post.name}
                            content={post.content}
                            onStartEdit={() => this.editPost(post.id)}
                            onDelete={() => this.deletePostHandler(post.id)}
                        />
                    ))}
                </Auth>
            </div>
        );
    }
}
