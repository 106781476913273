import React, { useState } from 'react';
import Auth from '../Auth/Auth';
import FilePicker from '../../components/Form/Input/FilePicker';
import Button from '../../components/Button/Button';
import Loader from '../../components/Loader/Loader';
/*
*********************************************************************************************************
*  @Parent Component           :    Blog,Service,Blog,CaseStudy of admin panel 
*  @React Component Name       :    ImageUrlGenerator
*  @Author                     :    <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*  @Company                    :    Antrazal
*  @Date                       :    04-07-2024
* 
*******************************************************************************************************
* Developer                                             TITLE
* <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)       Progressive add products LWC component development
********************************************************************************************************
*/
const ImageUrlGenerator = () => {

    const [formData, setFormData] = useState({ photo: { value: '', valid: false, touched: false } });
    const [postsLoading, setPostsLoading] = useState(false);
    const [imageURL, setImageURL] = useState('');

    /*
      *********************************************************************************************************
      *   @React Component Name       :       ImageUrlGenerator
      *   @Method                     :       inputBlurHandler
      *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
      *   @Company                    :       Antrazal
      *   @Description                :       this method using to tuch or update the file uploader 
      *********************************************************************************************************
      */

    const inputBlurHandler = (input) => {
        setFormData((prevState) => ({
            ...prevState,
            [input]: {
                ...prevState[input],
                touched: true,
            },
        }));
    };

    /*
      *********************************************************************************************************
      *   @React Component Name       :       ImageUrlGenerator
      *   @Method                     :       postInputChangeHandler
      *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
      *   @Company                    :       Antrazal
      *   @Description                :       uploade the image on s3 bucket using the server api
      *********************************************************************************************************
      */

    const postInputChangeHandler = async (input, value, files) => {
        if (files && files.length > 0) {
            const formData = new FormData();
            formData.append('file', files[0]);
            try {
                setPostsLoading(true);
                const response = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/upload`, {
                    method: 'POST',
                    body: formData,
                });
                if (response.ok) {
                    const { url } = await response.json();
                    const fieldToUpdate = input === 'photo' ? 'photo' : '';
                    setFormData((prevState) => ({
                        ...prevState,
                        [fieldToUpdate]: {
                            ...prevState[fieldToUpdate],
                            valid: true,
                            value: url,
                        },
                    }));
                } else {
                    throw new Error('Failed to upload image');
                }
            } catch (error) {
                console.error('Error uploading image:', error);
            } finally {
                setPostsLoading(false);
            }
        }
    };

    /*
      *********************************************************************************************************
      *   @React Component Name       :       ImageUrlGenerator
      *   @Method                     :       onGetUrl
      *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
      *   @Company                    :       Antrazal
      *   @Description                :       Get the public URL of an image
      *********************************************************************************************************
      */

    const onGetUrl = (event) => {
        event.preventDefault();
        const { photo } = formData;
        const requestData = {
            photo: photo.value,
        };
        setImageURL(requestData.photo);
    };

    /*
    *********************************************************************************************************
    *   @React Component Name       :       ImageUrlGenerator
    *   @Method                     :       copyToClipboard
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       this method using for copy the image url 
    *********************************************************************************************************
    */

    const copyToClipboard = () => {
        const copyText = document.getElementById('image-url');
        copyText.select();
        document.execCommand('copy');
    };

    return (
        <div>
            <Auth>
                <form onSubmit={onGetUrl}>
                    <FilePicker
                        id="photo"
                        label="Choose file For get the public URL of Images"
                        control="input"
                        onChange={postInputChangeHandler}
                        onBlur={() => inputBlurHandler('photo')}
                        value={formData['photo'].value}
                        valid={formData['photo'].valid}
                        touched={formData['photo'].touched}
                    />
                    {
                        (postsLoading === true) ? (<Loader />) : (
                            <Button design="raised" type="submit">
                                Generate the URL
                            </Button>
                        )
                    }

                </form>
                {imageURL.length <= 0 ? (
                    <p style={{ textAlign: 'center' }}>No URL</p>
                ) : (
                    <>
                        <input
                            type="text"
                            id="image-url"
                            value={imageURL}
                            readOnly
                            style={{ position: 'absolute', left: '-9999px' }}
                        />
                        <div style={{ marginTop: "1rem" }}>
                            <Button design="raised" onClick={copyToClipboard} >
                                Copy URL
                            </Button>
                        </div>
                        <img src={imageURL} alt="" style={{ width: "5rem", margin: "1rem 0" }} />
                    </>
                )}
            </Auth>

        </div>
    );
};
export default ImageUrlGenerator;
