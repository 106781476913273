import React, { useState, useEffect } from 'react'
import "../Components/css/Navbar.css"
import { Link } from 'react-router-dom';

/*
*********************************************************************************************************
*  @Parent Component           :    NavBar
*  @React Component Name       :    ServiceDropdown
*  @Author                     :    <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*  @Company                    :    Antrazal
*  @Date                       :    05-07-2024
* 
*******************************************************************************************************
* Developer                                             TITLE
* <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)       Service DropDown 
********************************************************************************************************
*/

export default function ServiceDropdown(props) {
    const [isdropdownState, setIsdropdownState] = useState(false);
    const [posts, setPosts] = useState([]);
    const [windowDimension, detectHW] = useState({
        winWidth: window.innerWidth,
        winHeight: window.innerHeight,
    })

    /*
    *********************************************************************************************************
    *   @React Component Name       :       ServiceDropdown
    *   @Method                     :       detectSize
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       calculate the size of the window and set the state 
    *********************************************************************************************************
    */

    const detectSize = () => {
        detectHW({
            winWidth: window.innerWidth,
            winHeight: window.innerHeight,
        })
    }

    /*
    *********************************************************************************************************
    *   @React Component Name       :       ServiceDropdown
    *   @Method                     :       fetchPosts
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       fetch the data of the services and and store it 
    *********************************************************************************************************
    */

    const fetchPosts = () => {
        fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/services`)
            .then((res) => res.json())
            .then((resData) => {
                setPosts(resData);
            })
            .catch((err) => {
                console.error('Error fetching blog posts:', err);
            });
    };

    /*
    *********************************************************************************************************
    *   @React Component Name       :       ServiceDropdown
    *   @Method                     :       useEffect
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       call the api on rendering 
    *********************************************************************************************************
    */

    useEffect(() => {
        fetchPosts();
        window.addEventListener('resize', detectSize)
        return () => {
            window.removeEventListener('resize', detectSize)
        }
    }, [windowDimension])

    /*
    *********************************************************************************************************
    *   @React Component Name       :       ServiceDropdown
    *   @Method                     :       handleDropdown
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       show or hide the dropdown 
    *********************************************************************************************************
    */

    const handleDropdown = event => {
        setIsdropdownState(current => !current);
    }

    return (
        <li className={isdropdownState ? "dropdown__item show-dropdown" : "dropdown__item "}>
            <div className="nav__link dropdown__button" onClick={handleDropdown}>
                Service
                <i className="ri-arrow-down-s-line dropdown__arrow" onClick={handleDropdown}></i>
            </div>
            <div className={windowDimension.winWidth && isdropdownState ? "dropdown__container height_dropdown" : "dropdown__container dropdown__container2"} >
                <div className="dropdown__content">
                    {
                        posts.map((val, index) => {
                            const { id, name, serviceIcon } = val;
                            const newName = name.replace(/&/g, "%26");
                            return (
                                <div className="dropdown__group " key={index} onClick={() => props.handleState()}>
                                    <div className="dropdown__icon">
                                        <img src={serviceIcon} alt="" style={{ width: "2rem", marginRight: "0.5rem" }} />
                                    </div>
                                    <Link
                                        to={{
                                            pathname: "/services/",
                                            search: `?page=${newName}`,
                                        }}
                                        style={{ display: 'grid', placeItems: 'center' }} >
                                        <span className="dropdown__title">Salesforce {name}</span>
                                    </Link>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </li>

    )
}



