import React from 'react';

import './MobileToggle.css';

const mobileToggle = props => (
  <>
  <button className="mobile-toggle" onClick={props.onOpen}>
    <span className="mobile-toggle__bar" />
    <span className="mobile-toggle__bar" />
    <span className="mobile-toggle__bar" />
  </button>
  </>
);

export default mobileToggle;
