import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { HashLink as Link } from "react-router-hash-link";
import { email, required } from '../adminPanelSRC/util/validators';
import "../Components/css/Footer.css"

/*
*********************************************************************************************************
*  @Parent Component           :    Main page 
*  @React Component Name       :    Footer
*  @Author                     :    <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*  @Company                    :    Antrazal
*  @Date                       :    05-07-2024
* 
*******************************************************************************************************
* Developer                                             TITLE
* <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)       footer section
********************************************************************************************************
*/

export default class Footer extends Component {

	constructor(props) {
		super(props);
		this.state = {
			onsubmitContactUsForm: {
				email: {
					value: '',
					valid: false,
					touched: false,
					validators: [required, email]
				},
			},
			formIsValid: false
		};
	}

	/*
	*********************************************************************************************************
	*   @React Component Name       :       Footer
	*   @Method                     :       inputChangeHandler
	*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
	*   @Company                    :       Antrazal
	*   @Description                :       It set the value onchange in the input field 
	*********************************************************************************************************
	*/

	inputChangeHandler = (input, value) => {
		this.setState(prevState => {
			const updatedForm = {
				...prevState.onsubmitContactUsForm,
				[input]: {
					...prevState.onsubmitContactUsForm[input],
					value: value,
					valid: prevState.onsubmitContactUsForm[input].validators.every(validator => validator(value)),
					touched: true
				}
			};
			let formIsValid = true;
			for (const inputName in updatedForm) {
				formIsValid = formIsValid && updatedForm[inputName].valid;
			}
			return {
				onsubmitContactUsForm: updatedForm,
				formIsValid: formIsValid
			};
		});
	};

	/*
	*********************************************************************************************************
	*   @React Component Name       :       Footer
	*   @Method                     :       inputBlurHandler
	*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
	*   @Company                    :       Antrazal
	*   @Description                :       This method is an event handler designed to handle
	*********************************************************************************************************
	*/

	inputBlurHandler = input => {
		this.setState(prevState => {
			return {
				onsubmitContactUsForm: {
					...prevState.onsubmitContactUsForm,
					[input]: {
						...prevState.onsubmitContactUsForm[input],
						touched: true
					}
				}
			};
		});
	};

	/*
	*********************************************************************************************************
	*   @React Component Name       :       Footer
	*   @Method                     :       setNullAllInputField
	*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
	*   @Company                    :       Antrazal
	*   @Description                :       Set all value null 
	*********************************************************************************************************
	*/

	setNullAllInputField = () => {
		this.setState((prevState) => ({
			onsubmitContactUsForm: {
				...prevState.onsubmitContactUsForm,

				email: {
					...prevState.onsubmitContactUsForm.email,
					value: '',
				},
			}
		}));
	};

	/*
	*********************************************************************************************************
	*   @React Component Name       :       Footer
	*   @Method                     :       onSubmitForm
	*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
	*   @Company                    :       Antrazal
	*   @Description                :       this method call the api and save the data in the Db
	*********************************************************************************************************
	*/

	onSubmitForm = (event) => {
		event.preventDefault();
		const { email, } = this.state.onsubmitContactUsForm;
		const requestData = {
			email: email.value,
			phonenumber: '',
			createdBy: email.value,
			updatedBy: email.value,
		};
		fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/Leets`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(requestData),
		})
			.then((res) => res.json())
			.then((data) => {
				this.setNullAllInputField()
			})
			.catch((err) => {
				console.error('Error submitting contact form:', err);
			});
	};

	render() {
		return (
			<div className="">
				<div className="footer-section">
					<div className="footer-content pt-5 pb-5" style={{ "zIndex": "auto" }}>
						<Row>
							<Col lg={4} xl={4} className=" mb-50">
								<div className="footer-widget" style={{ float: "left" }}>
									<div className="footer-logo">
										<a href="">
											<img
												src="/assets/ant_White_logo_26.png"
												className="img-fluid"
												alt="logo" />
										</a>
									</div>
									<div className="footer-text">
										<p>We're salesforce consulting company delivering exceptional Salesforce services worldwide. With global expertise and a team of experts,  Antrazal assists businesses of all sizes to streamline their business processes in the most sophisticated and seamless way.</p>
									</div>
								</div>
							</Col>
							<Col lg={4} xl={4} md={6} className=" mb-30">
								<div className="footer-widget" style={{ float: "left" }}>
									<div className="footer-widget-heading">
										<h3>Information</h3>
									</div>
									<ul className='ul-class'>
										<li> <Link to="/#work" >Work</Link></li>
										<li><NavLink to="/#home">Home</NavLink></li>
										<li><Link to="/#service">Services</Link></li>
										<li><Link to="/aboutUs">About us</Link></li>
										<li><Link to="/#">Expert Team</Link></li>
										<li><Link to="/#contact">Contact us</Link></li>
										<li><Link to="/#">Latest News</Link></li>
									</ul>
								</div>
							</Col>
							<Col lg={4} xl={4} md={6} className=" mb-50">
								<div className="footer-widget" style={{ float: "left" }}>
									<div className="footer-widget-heading">
										<h3>Subscribe</h3>
									</div>
									<div className="footer-text mb-25">
										<p>Don’t miss to subscribe to our new feeds, kindly fill the form below.</p>
									</div>
									<div className="subscribe-form">
										<form action="" onSubmit={this.onSubmitForm}>
											<input
												className={this.state.onsubmitContactUsForm['email'].valid ? 'valid' : 'invalid'}
												id='email'
												type="text"
												placeholder="Email Address"
												value={this.state.onsubmitContactUsForm.email.value}
												onChange={(e) => this.inputChangeHandler('email', e.target.value)}
												onBlur={() => this.inputBlurHandler('email')}
											/>
											<button type='submit' disabled={!this.state.formIsValid}><i className="fab fa-telegram-plane"></i></button>
										</form>
									</div>
								</div>
							</Col>
						</Row>
					</div>
					<hr style={{ borderTop: "2px solid black", "opacity": "10%" }} />
					<Row className="f-row">
						<Col className="footer-social-icon">
							<a href="#"><i className="fab fa-facebook-f facebook-bg"></i></a>
							<a href="#"><i className="fab fa-twitter twitter-bg"></i></a>
							<a href="#"><i className="fab fa-linkedin-in linkedin-bg"></i></a>
						</Col>
						<Col className="copyright-text">
							<p>Copyright &copy; 2021 All right reserved </p>
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}
