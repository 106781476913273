const credentialCardApi = [
    {
        Salesforce: [
            {
                image: "assets/Credentials/Experience-Cloud-Consultant.png"
            },
            {
                image: "assets/Credentials/JavaScript-Developer-I.png"
            },
            {
                image: "assets/Credentials/Marketing-Cloud-Administrator.png"
            },
            {
                image: "assets/Credentials/Marketing-Cloud-Consultant.png"
            },
            {
                image: "assets/Credentials/Marketing-Cloud-Developer.png"
            },
            {
                image: "assets/Credentials/Marketing-Cloud-Email-Specialist.png"
            },
            {
                image: "assets/Credentials/Platform-App-Builder.png"
            },
            {
                image: "assets/Credentials/Administrator.png"
            },
            {
                image: "assets/Credentials/Platform-Developer-I.png"
            },
            {
                image: "assets/Credentials/Platform-Developer-II.png"
            },
            {
                image: "assets/Credentials/Sales-Cloud-Consultant.png"
            },
            {
                image: "assets/Credentials/Service-Cloud-Consultant.png"
            },
        ],

    },

    {
        id: 2,
        title: "vlocity",
        Vlocity: [
            {

                image: "assets/Credentials/Industries-CPQ-Developer.png"
            },
            {
                image: "assets/Credentials/OmniStudio-Developer.png"
            },
            {
                image: "assets/Credentials/OmniStudio-Consultant.png"
            },
        ],
    },
    {
        Copado: [
            {

                image: "assets/Credentials/download.png"
            },
            {
                image: "assets/Credentials/download (1).png"
            },
            {
                image: "assets/Credentials/download (2).png"
            },
            {
                image: "assets/Credentials/download (3).png"
            },
        ],
    },
]

export default credentialCardApi;