import React, { Component } from 'react';
import Input from '../../components/Form/Input/Input';
import Button from '../../components/Button/Button';
import Auth from '../Auth/Auth';
import Multiselect from 'multiselect-react-dropdown';
import FilePicker from '../../components/Form/Input/FilePicker';
import Post from '../../components/Feed/Post/Post';
import Loader from '../../components/Loader/Loader';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { v4 as uuidv4 } from 'uuid';
import FilePickerFORArray from './FilePickerFORArray';
import ImageUrlGenerator from './GetPublicUrlOfImages';

/*
*********************************************************************************************************
*  @Parent Component           :    App Component Admin Panel 
*  @React Component Name       :    CaseStudy
*  @Author                     :    <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*  @Company                    :    Antrazal
*  @Date                       :    04-07-2024
* 
*******************************************************************************************************
* Developer                                             TITLE
* <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)       Progressive add products LWC component development
********************************************************************************************************
*/

class CaseStudy extends Component {

    /*
    *********************************************************************************************************
    *   @React Component Name       :       CaseStudy
    *   @Method                     :       constructor
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       initialized input variables
    *********************************************************************************************************
    */

    constructor(props) {
        super(props);
        this.state = {
            editorState: EditorState.createEmpty(),
            formData: [],
            submitBtn: false,
            caseStudyForm: {
                name: {
                    value: '',
                    valid: false,
                    validators: [],
                },
                image: {
                    value: '',
                    valid: false,
                    validators: [],
                },
                content: {
                    value: '',
                    valid: false,
                    validators: [],
                },
                authorImage: {
                    value: '',
                    valid: false,
                    validators: [],
                },
                authorName: {
                    value: '',
                    valid: false,
                    validators: [],
                },
                shotDescriptions: {
                    value: '',
                    valid: false,
                    validators: [],
                },
                imageURLs: [],
            },
            formShow: false,
            services: [],
            selectedServices: [],
            posts: [],
            freeArray: [],
            postsLoading: false,
            editPostBtn: false,
            editPostId: null,
            urlLoading: false,
        };
    }

    /*
       *********************************************************************************************************
       *   @React Component Name       :       CaseStudy
       *   @Method                     :       inputChangeHandler
       *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
       *   @Company                    :       Antrazal
       *   @Description                :       it handles the input changes
       *********************************************************************************************************
       */

    inputChangeHandler = (input, value) => {
        this.setState((prevState) => {
            let isValid = true;
            for (const validator of prevState.caseStudyForm[input].validators) {
                isValid = isValid && validator(value);
            }
            const updatedForm = {
                ...prevState.caseStudyForm,
                [input]: {
                    ...prevState.caseStudyForm[input],
                    valid: isValid,
                    value: value,
                },
            };
            let formIsValid = true;
            for (const inputName in updatedForm) {
                formIsValid = formIsValid && updatedForm[inputName].valid;
            }
            return {
                caseStudyForm: updatedForm,
                formIsValid: formIsValid,
            };
        });
    };

    /*
       *********************************************************************************************************
       *   @React Component Name       :       CaseStudy
       *   @Method                     :       toggleForm
       *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
       *   @Company                    :       Antrazal
       *   @Description                :       toggle the form for the creating or updating mode
       *********************************************************************************************************
       */

    toggleForm = () => {
        this.setState({ selectedServices: [], formData: [] });
                    this.setNullAllInputField();
        this.setState((prevState) => ({
            formShow: !prevState.formShow,
        }));

    };

    /*
       *********************************************************************************************************
       *   @React Component Name       :       CaseStudy
       *   @Method                     :       getServiceData
       *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
       *   @Company                    :       Antrazal
       *   @Description                :       Get all services Data from the backend.
       *********************************************************************************************************
       */

    getServiceData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/services`);
            if (!response.ok) {
                throw new Error('Failed to fetch Service data');
            }
            const data = await response.json();
            const serviceData = data.map((service) => ({
                id: service.id,
                name: service.name,
            }));
            this.setState({ services: serviceData });
        } catch (error) {
            console.error('Error fetching country data:', error);
        }
    };

    /*
       *********************************************************************************************************
       *   @React Component Name       :       CaseStudy
       *   @Method                     :       handleSelect
       *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
       *   @Company                    :       Antrazal
       *   @Description                :       this set the state of service in id or name format
       *********************************************************************************************************
       */

    handleSelect = (selectedList, selectedItem) => {
        const selectedServices = selectedList.map((service) => ({
            id: service.id,
            name: service.name,
        }));
        this.setState({ selectedServices: selectedServices });
    };

    /*
       *********************************************************************************************************
       *   @React Component Name       :       CaseStudy
       *   @Method                     :       handleRemove
       *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
       *   @Company                    :       Antrazal
       *   @Description                :       his remove the selected service from the form
       *********************************************************************************************************
       */

    handleRemove = (selectedList, selectedItem) => {
        if (!selectedItem) {
            console.warn('selectedItem is undefined in handleRemove');
            return;
        }
        const updatedServices = this.state.selectedServices.filter(
            (service) => service.id !== selectedItem.id
        );
        this.setState({ selectedServices: updatedServices });
    };

    /*
       *********************************************************************************************************
       *   @React Component Name       :       CaseStudy
       *   @Method                     :       componentDidMount
       *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
       *   @Company                    :       Antrazal
       *   @Description                :       this get all services or blog data initialy or any updation
       *********************************************************************************************************
       */

    componentDidMount() {
        this.getServiceData();
        this.fetchPosts();
    }

    /*
       *********************************************************************************************************
       *   @React Component Name       :       CaseStudy
       *   @Method                     :       postInputChangeHandler
       *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
       *   @Company                    :       Antrazal
       *   @Description                :       this method upload the images in the s3 bucket using the server
       *********************************************************************************************************
       */

    postInputChangeHandler = async (input, value, files) => {
        if (files && files.length > 0) {
            const formData = new FormData();
            formData.append('file', files[0]);
            try {
                const response = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/upload`, {
                    method: 'POST',
                    body: formData,
                });

                if (response.ok) {
                    const { url } = await response.json();
                    const fieldToUpdate = input === 'image' ? 'image' : 'authorImage';
                    this.setState((prevState) => ({
                        caseStudyForm: {
                            ...prevState.caseStudyForm,
                            [fieldToUpdate]: {
                                ...prevState.caseStudyForm[fieldToUpdate],
                                valid: true,
                                value: url,
                            },
                        },
                    }));
                } else {
                    console.error('Failed to upload image');
                }
            } catch (error) {
                alert.error('Error uploading image:', error);
            }
        }
    };

    /*
       *********************************************************************************************************
       *   @React Component Name       :       CaseStudy
       *   @Method                     :       setNullAllInputField
       *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
       *   @Company                    :       Antrazal
       *   @Description                :       set all input value null
       *********************************************************************************************************
       */

    setNullAllInputField = () => {
        this.setState((prevState) => ({
            caseStudyForm: {
                ...prevState.caseStudyForm,
                name: {
                    ...prevState.caseStudyForm.name,
                    value: '',
                },
                image: {
                    ...prevState.caseStudyForm.image,
                    value: '',
                },
                content: {
                    ...prevState.caseStudyForm.content,
                    value: '',
                },
                authorImage: {
                    ...prevState.caseStudyForm.authorImage,
                    value: '',
                },
                authorName: {
                    ...prevState.caseStudyForm.authorName,
                    value: '',
                },
                shotDescriptions: {
                    ...prevState.caseStudyForm.shotDescriptions,
                    value: '',
                },
                imageURLs: []
            },
            editorState: EditorState.createEmpty(),
        }));
    };

    /*
       *********************************************************************************************************
       *   @React Component Name       :       CaseStudy
       *   @Method                     :       onCaseStudy
       *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
       *   @Company                    :       Antrazal
       *   @Description                :       this function save or update all input data in the database
       *********************************************************************************************************
       */

    onCaseStudy = (event) => {
        event.preventDefault();
        const { name, image, content, shotDescriptions, imageURLs, authorName, authorImage } = this.state.caseStudyForm;
        const requestData = {
            name: name.value,
            image: image.value,
            authorName: authorName.value,
            authorImage: authorImage.value,
            shotDescriptions: shotDescriptions.value,
            multiImages: imageURLs,
            content: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())),
            serviceId: this.state.selectedServices.map((service) => service.id),
            createdBy: localStorage.getItem('userId'),
            updatedBy: localStorage.getItem('userId'),
        };
        if (this.state.editPostBtn) {
            fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/caseStudy/` + this.state.editPostId, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            })
                .then(res => {
                    res.json()
                })
                .then(data => {
                    this.toggleForm();
                    this.fetchPosts();
                    this.setNullAllInputField();
                    this.setState({ selectedServices: [] });
                    this.setState({
                        formData: [],
                        editPostBtn: false,
                        editPostId: null,
                    });
                })
                .catch(err => {
                    alert(err.message)
                    console.error('Error updating testimonial:', err);
                });
        } else {
            fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/caseStudy`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            })
                .then(res => {
                    return res;
                })
                .then(data => {
                    if (data.statusCode === 500) {
                        alert(data.message)
                    }
                    this.toggleForm();
                    this.fetchPosts();
                    this.setState({ selectedServices: [], formData: [] });
                    this.setNullAllInputField();
                })
                .catch(err => {
                    console.error('Error creating testimonial:', err);
                });
        }
    };

    /*
       *********************************************************************************************************
       *   @React Component Name       :       CaseStudy
       *   @Method                     :       fetchPosts
       *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
       *   @Company                    :       Antrazal
       *   @Description                :       this method call api for fetch caseStudy data from the server
       *********************************************************************************************************
       */

    fetchPosts = () => {
        this.setState({ postsLoading: true });
        fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/caseStudy`)
            .then((res) => res.json())
            .then((resData) => {
                this.setState({ posts: resData, postsLoading: false });
            })
            .catch((err) => {
                console.error('Error fetching blog posts:', err);
                this.setState({ postsLoading: false });
            });
    };

    /*
       *********************************************************************************************************
       *   @React Component Name       :       CaseStudy
       *   @Method                     :       deletePostHandler
       *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
       *   @Company                    :       Antrazal
       *   @Description                :       this method using for the delete the blog.
       *********************************************************************************************************
       */

    deletePostHandler = (postId) => {
        this.setState({ postsLoading: true });
        fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/caseStudy/${postId}`, {
            method: 'DELETE',
            headers: {
                Authorization: 'Bearer ' + this.props.token,
            },
        })
            .then((res) => {
                if (res.status !== 200 && res.status !== 201) {
                    throw new Error('Deleting a post failed!');
                }
                this.fetchPosts();
                return res.json();
            })
            .catch((err) => {
                console.error('Error deleting post:', err);
                this.setState({ postsLoading: false });
            });
    };

    /*
       *********************************************************************************************************
       *   @React Component Name       :       CaseStudy
       *   @Method                     :       inputBlurHandler
       *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
       *   @Company                    :       Antrazal
       *   @Description                :       this method used for blur functionality of input
       *********************************************************************************************************
       */

    inputBlurHandler = (input) => {
        this.setState((prevState) => ({
            caseStudyForm: {
                ...prevState.caseStudyForm,
                [input]: {
                    ...prevState.caseStudyForm[input],
                    touched: true,
                },
            },
        }));
    };

    /*
       *********************************************************************************************************
       *   @React Component Name       :       CaseStudy
       *   @Method                     :       onEditorStateChange
       *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
       *   @Company                    :       Antrazal
       *   @Description                :       this method using for the write the Blog editor or set the value of the editor of the variable
       *********************************************************************************************************
       */

    onEditorStateChange = (newEditorState) => {
        const content = draftToHtml(convertToRaw(newEditorState.getCurrentContent()));
        this.setState((prevState) => ({
            editorState: newEditorState,
            caseStudyForm: {
                ...prevState.caseStudyForm,
                content: {
                    ...prevState.caseStudyForm.content,
                    value: content,
                    valid: true,
                },
            },
        }));
    };

    /*
       *********************************************************************************************************
       *   @React Component Name       :       CaseStudy
       *   @Method                     :       fetchSinglePost
       *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
       *   @Company                    :       Antrazal
       *   @Description                :       fetch single post data from the server
       *********************************************************************************************************
       */

    fetchSinglePost = (postId) => {
        this.setState({ postsLoading: true });
        fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/caseStudy/` + postId)
            .then(res => {
                if (res.status !== 200) {
                    throw new Error('Failed to fetch post data.');
                }
                return res.json();
            })
            .then(resData => {
                const contentBlock = htmlToDraft(resData.content || '<p>Your initial content.</p>');
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const array = resData.services.map(service => ({
                    id: service.id,
                    name: service.name
                }))
                const arrayImages = resData.multiImages.map(caseStudy => ({
                    id: caseStudy.id,
                    value: caseStudy.url
                }))
                const arrayImages2 = resData.multiImages.map(caseStudy => ({
                    id: caseStudy.id,
                    url: caseStudy.url
                }))
                const selectedServices = array.map((service) => service.id);
                this.setState(prevState => ({
                    caseStudyForm: {
                        ...prevState.caseStudyForm,
                        name: {
                            ...prevState.caseStudyForm.name,
                            value: resData.name
                        },
                        image: {
                            ...prevState.caseStudyForm.image,
                            value: resData.image
                        },
                        authorImage: {
                            ...prevState.caseStudyForm.authorImage,
                            value: resData.authorImage
                        },
                        authorName: {
                            ...prevState.caseStudyForm.authorName,
                            value: resData.authorName
                        },
                        content: {
                            ...prevState.caseStudyForm.content,
                            value: resData.content,
                            valid: true,
                        },
                        shotDescriptions: {
                            ...prevState.caseStudyForm.shotDescriptions,
                            value: resData.shotDescriptions,
                            valid: true,
                        },
                    },
                    selectedServices: selectedServices,
                    editorState: EditorState.createWithContent(contentState),

                }));
                this.setState(prevState => ({
                    ...prevState,
                    formData: arrayImages,
                    caseStudyForm: {
                        ...prevState.caseStudyForm,
                        imageURLs: arrayImages2,
                    },
                }));
                this.handleRemove(resData.services);
                this.handleSelect(resData.services);

                this.postInputChangeHandlerForFilePicker(arrayImages)
            })
            .catch(error => {
                console.error('Error fetching post data:', error);
            })
            .finally(() => {
                this.setState({ postsLoading: false });
            });
    };

    /*
       *********************************************************************************************************
       *   @React Component Name       :       CaseStudy
       *   @Method                     :       editPost
       *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
       *   @Company                    :       Antrazal
       *   @Description                :       this function open the form for editing or render all data in input field
       *********************************************************************************************************
       */

    editPost = postId => {
        this.setState({
            editPostBtn: true,
            editPostId: postId
        }, () => {
            this.fetchSinglePost(postId);
            this.toggleForm();
        });
    };

    /*
   *********************************************************************************************************
   *   @React Component Name       :       CaseStudy
   *   @Method                     :       addFilePicker
   *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
   *   @Company                    :       Antrazal
   *   @Description                :       this function add the id of the perticular image picker
   *********************************************************************************************************
   */

    addFilePicker = () => {
        this.setState((prevState) => ({
            formData: [
                ...prevState.formData,
                { id: uuidv4(), value: '', valid: false, touched: false },
            ],
        }));
    };

    /*
   *********************************************************************************************************
   *   @React Component Name       :       CaseStudy
   *   @Method                     :       removeFilePicker
   *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
   *   @Company                    :       Antrazal
   *   @Description                :       this function using for the remove the file picker
   *********************************************************************************************************
   */

    removeFilePicker = (id) => {
        this.setState((prevState) => {
            const updatedFormData = prevState.formData.filter((picker) => picker.id !== id);
            const updatedImageURLs = prevState.caseStudyForm.imageURLs.filter((url) => url.id !== id);

            return {
                formData: updatedFormData,
                caseStudyForm: {
                    ...prevState.caseStudyForm,
                    imageURLs: updatedImageURLs,
                },
            };
        });
    };

    /*
   *********************************************************************************************************
   *   @React Component Name       :       CaseStudy
   *   @Method                     :       inputBlurHandlerForFilePicker
   *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
   *   @Company                    :       Antrazal
   *   @Description                :       this function using for the blue of the image uploader
   *********************************************************************************************************
   */

    inputBlurHandlerForFilePicker = (id) => {
        this.setState((prevState) => ({
            formData: prevState.formData.map((picker) =>
                picker.id === id ? { ...picker, touched: true } : picker
            ),
        }));
    };

    /*
       *********************************************************************************************************
       *   @React Component Name       :       CaseStudy
       *   @Method                     :       postInputChangeHandlerForFilePicker
       *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
       *   @Company                    :       Antrazal
       *   @Description                :       this function using for the blue of the image uploader in the s3 bucket
       *********************************************************************************************************
       */

    postInputChangeHandlerForFilePicker = async (id, value, files) => {
        return new Promise(async (resolve, reject) => {
            if (files && files.length > 0) {
                const formData = new FormData();
                formData.append('file', files[0]);
                try {
                    const response = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/upload`, {
                        method: 'POST',
                        body: formData,
                    });

                    if (response.ok) {
                        const { url } = await response.json();
                        this.setState(
                            (prevState) => {
                                const updatedFormData = prevState.formData.map((picker) =>
                                    picker.id === id ? { ...picker, valid: true, value: url } : picker
                                );
                                const updatedImageURLs = [...prevState.caseStudyForm.imageURLs, { id, url }];
                                const updatedCaseStudyForm = {
                                    ...prevState.caseStudyForm,
                                    imageURLs: updatedImageURLs,
                                };
                                return {
                                    formData: updatedFormData,
                                    caseStudyForm: updatedCaseStudyForm,
                                };
                            },
                            () => {
                                resolve();
                                this.setState({
                                    urlfetched: true,
                                });
                            }
                        );
                    } else {
                        reject('Failed to upload image');
                    }
                } catch (error) {
                    console.error('Error uploading image:', error);
                    reject(error);
                }
            } else {
                resolve();
            }
        });
    };

    /*
       *********************************************************************************************************
       *   @React Component Name       :       CaseStudy
       *   @Method                     :       sendFile
       *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
       *   @Company                    :       Antrazal
       *   @Description                :       this function using for the file perticular id or there value
       *********************************************************************************************************
       */

    sendFile = async (id, value) => {
        try {
            await this.postInputChangeHandler(id, value, value);
        } catch (error) {
            console.error('Error sending file:', error);
        }
    };

    /*
       *********************************************************************************************************
       *   @React Component Name       :       CaseStudy
       *   @Method                     :       handleSendButtonClick
       *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
       *   @Company                    :       Antrazal
       *   @Description                :       this function using for the send the file in the image
       *********************************************************************************************************
       */

    handleSendButtonClick = async (id, value) => {
        try {
            this.setState({ urlLoading: true });
            await this.sendFile(id, value);
        } finally {
            this.setState({ urlLoading: false });
        }
    };

    render() {
        const { editorState } = this.state;
        const { formData, urlLoading, imageURLs, urlError } = this.state;
        return (
            <div className="main-div-login">
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: '1rem 0 ',
                    }}
                >
                    <button onClick={this.toggleForm} className='buttonCreatePost'>
                        {this.state.formShow ? 'Close CaseStudy form' : 'Create New CaseStudy'}
                    </button>
                </div>
                <ImageUrlGenerator />
                {this.state.formShow && (
                    <Auth>
                        <form onSubmit={this.onCaseStudy}>
                            <Input
                                id="name"
                                label="CaseStudy Name"
                                type="text"
                                control="input"
                                onChange={this.inputChangeHandler}
                                value={this.state.caseStudyForm['name'].value}
                                valid={this.state.caseStudyForm['name'].valid}
                            />
                            <FilePicker
                                id="image"
                                label="CaseStudy image"
                                control="input"
                                onChange={this.postInputChangeHandler}
                                value={this.state.caseStudyForm['image'].value}
                                valid={this.state.caseStudyForm['image'].valid}
                                touched={this.state.caseStudyForm['image'].touched}
                                onBlur={this.inputBlurHandler.bind(this, 'image')}
                            />
                            <Input
                                id="shotDescriptions"
                                label="CaseStudy shotDescriptions"
                                type="text"
                                control="input"
                                onChange={this.inputChangeHandler}
                                value={this.state.caseStudyForm['shotDescriptions'].value}
                                valid={this.state.caseStudyForm['shotDescriptions'].valid}
                            />
                            <Input
                                id="authorName"
                                label="CaseStudy authorName"
                                type="text"
                                control="input"
                                onChange={this.inputChangeHandler}
                                value={this.state.caseStudyForm['authorName'].value}
                                valid={this.state.caseStudyForm['authorName'].valid}
                            />
                            <FilePicker
                                id="authorImage"
                                label="CaseStudy authorImage"
                                control="input"
                                onChange={this.postInputChangeHandler}
                                value={this.state.caseStudyForm['authorImage'].value}
                                valid={this.state.caseStudyForm['authorImage'].valid}
                                touched={this.state.caseStudyForm['authorImage'].touched}
                                onBlur={this.inputBlurHandler.bind(this, 'authorImage')}
                            />
                            <form className="row g-3" method="post" style={{ margin: '1rem 0' }}>
                                <div className="">
                                    <label className="form-label">Select Service</label>
                                    <div className="text-dark">
                                        <Multiselect
                                            isObject
                                            onSelect={this.handleSelect}
                                            options={this.state.services}
                                            onRemove={this.handleRemove}
                                            displayValue="name"
                                            showCheckbox={true}
                                            selectedValues={this.state.selectedServices}
                                        />
                                    </div>
                                </div>
                            </form>
                            <form>
                                {formData.map((picker) => (
                                    <div key={picker.id} className='filepinputfield'>
                                        <FilePickerFORArray
                                            id={picker.id}
                                            label={`Choose Icon images ${formData.indexOf(picker) + 1}`}
                                            control="input"
                                            onChange={(value, files) =>
                                                this.postInputChangeHandlerForFilePicker(picker.id, value, files)
                                            }
                                            onBlur={() => this.inputBlurHandlerForFilePicker(picker.id)}
                                            value={picker.value}
                                            valid={picker.valid}
                                            touched={picker.touched}
                                            urlLoading={urlLoading}
                                        />
                                        <div className='inputfieldbutton'>
                                            <div onClick={() => this.removeFilePicker(picker.id)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                                                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className='imageuploaderbutton'>
                                    <Button design="raised" type="button" onClick={this.addFilePicker}>
                                        Add File Picker
                                    </Button>
                                </div>
                            </form>
                            <Editor
                                editorState={editorState}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={this.onEditorStateChange}
                            />
                            {this.state.editPostBtn ? (
                                <Button design="raised" type="submit" loading={this.props.loading}>
                                    Update CaseStudy
                                </Button>
                            ) : (
                                <Button design="raised" type="submit" loading={this.props.loading}>
                                    Create CaseStudy
                                </Button>
                            )}
                        </form>
                    </Auth>
                )}
                <Auth>
                    {this.state.postsLoading && <Loader />}
                    {this.state.posts.length <= 0 && !this.state.postsLoading ? (
                        <p style={{ textAlign: 'center' }}>No posts found.</p>
                    ) : null}
                    {this.state.posts.map((post) => (
                        <Post
                            key={post.id}
                            id={post.id}
                            title={post.name}
                            content={post.shotDescriptions}
                            onStartEdit={() => this.editPost(post.id)}
                            onDelete={() => this.deletePostHandler(post.id)}
                        />
                    ))}
                </Auth>
            </div>
        );
    }
}

export default CaseStudy;


