import React from "react";
import { Container } from "react-bootstrap";
import '../Components/css/roiService.css'

function RoiFocused() {
	return (
		<>
			<section className="roiouterSection">
				<Container>
					<h1 className="heading" style={{ textAlign: "start",fontSize:"2.3rem"}}>
						Our ROI focused approach
						<br/>
						 to successful project deliveries
					</h1>
					<div style={{ display: "flex", justifyContent: "center" }}>
						<div className="roiServiceSetction">
							<div className="roiServiceSetction-inner-div">
								<h1>100+ Salesforce CRM Implementation</h1>
								<p>Sales/Service cloud</p>
								<p>Customer Development</p>
								<p>Integrations</p>
							</div>
							<div className="roiServiceSetction-inner-div">
								<h1>100+ Salesforce CRM Implementation</h1>
								<p>Sales/Service cloud</p>
								<p>Customer Development</p>
								<p>Integrations</p>
							</div>
							<div className="roiServiceSetction-inner-div">
								<h1>100+ Salesforce CRM Implementation</h1>
								<p>Sales/Service cloud</p>
								<p>Customer Development</p>
								<p>Integrations</p>
							</div>
						</div>
					</div>
				</Container>
			</section>
		</>
	);
}

export default RoiFocused;
