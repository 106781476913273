import React, { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import '../Components/css/countUpsection.css'

/*
*********************************************************************************************************
*  @Parent Component           :    
*  @React Component Name       :    CountUpSection
*  @Author                     :    <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*  @Company                    :    Antrazal
*  @Date                       :    05-07-2024
* 
*******************************************************************************************************
* Developer                                             TITLE
* <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)       CountUpSection section 
********************************************************************************************************
*/

function CountUpSection() {

	const [posts, setPosts] = useState([]);

	/*
	*********************************************************************************************************
	*   @React Component Name       :       CountUpSection
	*   @Method                     :       fetchPosts
	*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
	*   @Company                    :       Antrazal
	*   @Description                :       fetch the achievements data from the server
	*********************************************************************************************************
	*/

	const fetchPosts = () => {
		fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/achievements`)
			.then((res) => res.json())
			.then((resData) => {
				setPosts(resData);
			})
			.catch((err) => {
				console.error('Error fetching blog posts:', err);
			});
	};

	useEffect(() => {
		fetchPosts();
	}, []);

	return (
		<section className=" main_section">
			<Container style={{ "color": "white" }} className='container '>
				<div className="inner-div">
					<div className='countupbg_row'>
						{posts.map((currentelement) => {
							const { id, name, number } = currentelement;
							return (
								<div className='count-inner-div'>
									<h1>{number}</h1>
									<p>{name}</p>

								</div>
							);
						})}
					</div>
				</div>
			</Container>
		</section>
	)
}

export default CountUpSection