import React, { useState } from "react";
import credentialCardApi from "../Data/CredentialCardAPI";
import Container from "react-bootstrap/Container";
import "../Components/css/credentialsTabs.css";
import Navbar from "../Components/Navbar2";
/*
*********************************************************************************************************
*  @Parent Component           :    App main Page
*  @React Component Name       :    CredentialCard
*  @Author                     :    <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*  @Company                    :    Antrazal
*  @Date                       :    05-07-2024
* 
*******************************************************************************************************
* Developer                                             TITLE
* <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)       not use
********************************************************************************************************
*/
function CredentialCard() {

	const [badgesData, setBadgesData] = useState(credentialCardApi);

	return (
		<>
			<Navbar />
			<br />
			<br />
			<br />
			<div>
				<Container>
					<div className="">
						<p className="heading">Credentials</p>
						<p className="title_sub">
							Lorem Ipsum is simply dummy text of the printing and typesetting
							industry.
						</p>
					</div>
					<div
						className="card-header"
						style={{
							border: "1px solid #5555",
							"margin-top": "2rem",
							"border-radius": "1",
						}}
					>
						<h3 style={{ "font-weight": "600", color: "#909090" }}>
							Salesforce
						</h3>
						<div className="grid-container">
							{badgesData[0].Salesforce.map((currentElement) => {
								const { image } = currentElement;
								return (
									<>
										<div className="grid-item">
											<img src={image} style={{ width: "100%" }} alt="" />
										</div>
									</>
								);
							})}
						</div>
					</div>
					<div
						className="card-header"
						style={{
							border: "1px solid #5555",
							"margin-top": "2rem",
							"border-radius": "1",
						}}
					>
						<h3 style={{ "font-weight": "600", color: "#909090" }}>Vlocity</h3>
						<div className="grid-container">
							{badgesData[1].Vlocity.map((currentElement) => {
								const { image } = currentElement;
								return (
									<>
										<div className="grid-item">
											<img src={image} style={{ width: "100%" }} alt={image} />
										</div>
									</>
								);
							})}
						</div>
					</div>
					<div
						className="card-header"
						style={{
							border: "1px solid #5555",
							"margin-top": "2rem",
							"border-radius": "1",
						}}
					>
						<h3 style={{ "font-weight": "600", color: "#909090" }}>Copado</h3>
						<div className="grid-container">
							{badgesData[2].Copado.map((currentElement) => {
								const { image } = currentElement;
								return (
									<>
										<div className="grid-item">
											<img src={image} style={{ width: "100%" }} alt={image} />
										</div>
									</>
								);
							})}
						</div>
					</div>
				</Container>
			</div>
		</>
	);
}

export default CredentialCard;
