import React, { useState, useCallback, useEffect } from 'react';
import TaskDetailView from './TaskDetailView';
import { useSearchParams } from 'react-router-dom';
import TextEditor from './TextEditor';
import LoaderForHrm from './LoaderForHrm';
import SmallLoader from './SmallLoader';
import HrmNavbar from './HrmNavbar';
import Select from 'react-select';
export default function TaskDetailForNewWindow({ selectedId }) {
    const [searchParams] = useSearchParams();
    const name = searchParams.get("id");
    const [id, setId] = useState(selectedId || name || null);
    const [loading, setLoading] = useState(false);
    const [projectData, setProjectData] = useState([]);
    const [allUserOption, setAllUserOption] = useState([]);
    const [childProjectData, setChildProjectData] = useState([]);
    const [messageType, setMessageType] = useState('');
    const [messageFromBackend, setMessageFromBackend] = useState('');
    const [contact, setContact] = useState([]);
    const [childTaskId, setChildTaskId] = useState('')
    const [activeTab, setActiveTab] = useState('comments');
    const [projectHistory, setProjectHistory] = useState([]);
    const [relativeTask, setRelativeTask] = useState([]);
    const [projectComments, setprojectComments] = useState([]);
    const [newProjectHistory, setNewProjectHistory] = useState([]);
    const [loadingSectionRelatedItem, setLoadingSectionRelatedItem] = useState(false)
    const [loadingSectionComment, setLoadingSectionComment] = useState(false)
    const [loadingSectionHistory, setLoadingSectionHistory] = useState(false)
    const [loaderForNewComment, setLoaderForNewComment] = useState({ loading: true, Id: null })
    const [childLoading, setChildLoading] = useState(false)
    const [childHistory, setChildHistory] = useState([]);
    const [childRelatedTask, setChildRelatedTask] = useState([]);
    const [childComment, setChildComment] = useState([])
    const [childHistoryLoader, setChildHistoryLoader] = useState([]);
    const [childRelatedTaskLoader, setChildRelatedTaskLoader] = useState([]);
    const [childCommentLoader, setChildCommentLoader] = useState([])
    const [childActiveTab, setChildActiveTab] = useState('comments');
    const [firstConditonOfTaskChild, setFirstConditonOfTaskChild] = useState(false)
    const [chilFirstConditonOfTaskChild, setchilFirstConditonOfTaskChild] = useState(false);
    const [smallLoaderId, setSmallLoaderId] = useState(false);
    const [editedStatus, setEditedStatus] = useState({});
    const [WindowForActualHour, setWindowForActualHour] = useState(false)
    const [actualHours, setActualHours] = useState();
    const [idStateClosed, setStateClosed] = useState({ id: null, index: null })
    const [sliderToggle, setSliderToggle] = useState(false)
    const [closeTaskOldNewValue, setCloseTaskOldNewValue] = useState({oldValue:"",newValue:""})
    const setChildLoaderFun = (data) => {
        setChildLoading(data)
    }

    const fetchContact = useCallback(() => {
        fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/getContact`)
            .then((res) => res.json())
            .then((resData) => {
                setContact(resData.records);
                const userOptions = resData.records.map(contact => ({
                    label: contact.Name,
                    value: contact.Id
                }));
                setAllUserOption(userOptions);

            })
            .catch((err) => {
                setMessageWithTimeout('Contacts not fetched. Refresh', 'error');
                console.error('Error fetching contact:', err);
            });
    }, []);

    const fetchProjectRelativeTask = useCallback(async (id) => {
        setLoadingSectionRelatedItem(true);
        setRelativeTask([])
        try {
            const res = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/getEntityrelativeTask/${id}`);
            const resData = await res.json();
            setRelativeTask(resData.records);
        } catch (err) {
            setLoadingSectionRelatedItem(false);
            setMessageWithTimeout('Failed to fetch task relative tasks', 'error');
            console.error('Error fetching relative tasks:', err);
        } finally {
            setLoadingSectionRelatedItem(false);
        }
    }, []);

    const fetchProjectComments = useCallback(async (id) => {
        setLoadingSectionComment(true);
        setprojectComments([])
        try {
            const res = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/getEntitycomments/${id}`);
            const resData = await res.json();
            setprojectComments(resData.records);
        } catch (err) {
            setLoadingSectionComment(false);
            setMessageWithTimeout('Failed to fetch task project comments', 'error');
            console.error('Error fetching comments:', err);
        } finally {
            setLoadingSectionComment(false);
        }
    }, []);

    const fetchProjectHistory = useCallback(async (id) => {
        setLoadingSectionHistory(true);
        setNewProjectHistory([])
        try {
            const res = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/getEntityHistoryByTaskId/${id}`);
            const resData = await res.json();
            setNewProjectHistory(resData.records);
        } catch (err) {
            setMessageWithTimeout('Failed to fetch Task History', 'error');
            console.error('Error fetching history:', err);
        } finally {
            setLoadingSectionHistory(false);
        }
    }, []);

    const fetchProjectDetail = useCallback(async (id) => {
        setLoading(true);
        setProjectData([])
        setNewProjectHistory([])
        setprojectComments([])
        setRelativeTask([])
        try {
            const res = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/EntityDetails/${localStorage.getItem('contactUserId')}/${id}`);
            const resData = await res.json();
       
            setProjectData(resData.records);
            setLoading(false);
            const project = resData.records[0]
            if (
                ['Development', 'Solution Design', 'Unit Testing', 'Review', 'Deployment'].some(prefix => project.Title__c.startsWith(prefix)) &&
                project.RecordType.Name === 'Task' && project.Parent_Entity__r.RecordType.Name === "User Story"
            ) {
                setFirstConditonOfTaskChild(true);
                ToggleHistoryCommentTab('history')
            }
            else {
                setFirstConditonOfTaskChild(false);
            }
            await fetchProjectRelativeTask(id);

            await fetchProjectComments(id);

            await fetchProjectHistory(id);
        } catch (err) {
            setLoading(false);
            setMessageWithTimeout('Failed to fetch project data', 'error');
            console.error('Error fetching project details:', err);
        } finally {
            setLoading(false);
        }
    }, [fetchProjectRelativeTask, fetchProjectComments, fetchProjectHistory]);

    useEffect(() => {
        fetchContact();
    }, []);
    const SetLoader = (val) => {
        setLoading(val)
    }


    const updateProjectComments = (newComment) => {
        setprojectComments(prevProjectData => {
            const newRecord = {
                "Id": newComment.Id,
                "Comment__c": newComment.content,
                "CreatedDate": new Date().toISOString(),
                "Commented_By__r": {
                    "Name": localStorage.getItem('contactUserName')
                }
            };

            if (!prevProjectData || !prevProjectData.length || !prevProjectData[0].Project_Entity_Commnets__r) {
                const updatedProject = {
                    ...prevProjectData[0],
                    Project_Entity_Commnets__r: {
                        records: [newRecord]
                    }
                };
                return [updatedProject];
            }

            const existingRecords = [...prevProjectData[0].Project_Entity_Commnets__r.records];
            existingRecords.unshift(newRecord);

            const updatedProject = {
                ...prevProjectData[0],
                Project_Entity_Commnets__r: {
                    ...prevProjectData[0].Project_Entity_Commnets__r,
                    records: existingRecords
                }
            };

            return [updatedProject];
        });
    };


    useEffect(() => {
        if (id && allUserOption.length > 0) {
            fetchProjectDetail(id);

        }
    }, [id, allUserOption]);
    useEffect(() => {

    }, [projectComments, relativeTask, newProjectHistory]);

    const setMessageWithTimeout = (message, typeOfMessage) => {
        setMessageFromBackend(message);
        setMessageType(typeOfMessage);
        setTimeout(() => {
            setMessageFromBackend('');
            setMessageType('');
        }, 3000);
    };

    const getTypeColor = (item) => {
        switch (item) {
            case 'Bug':
                return 'red';
            case 'User Story':
                return 'green';
            case 'Task':
                return 'blue';
            default:
                return 'violet';
        }
    }

    const formatDateTime = (dateTimeString) => {
        if (!dateTimeString) {
            return '';
        }
        const date = new Date(dateTimeString);
        const day = date.getDate();
        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();
        const formattedDate = `${month} ${day} ${year}`;
        return formattedDate;
    };

    const handelChildDetailId = (id) => {
        setChildTaskId(id === childTaskId ? '' : id);
        SetChildActiveTabCommentOrHistory('comments')
        childFetchProjectDetail(id);
    };

    const ToggleHistoryCommentTab = (tab) => {
        setActiveTab(tab)
        if (tab == 'history') {
        }
    }

    function newFormatDateTime(dateTimeString) {
        const dateTime = new Date(dateTimeString);
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = months[dateTime.getMonth()];
        const day = dateTime.getDate().toString().padStart(2, '0');
        const year = dateTime.getFullYear();
        const formattedDate = `${day} ${month} ${year}`;
        let hours = dateTime.getHours();
        const minutes = dateTime.getMinutes().toString().padStart(2, '0');
        const period = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        const formattedTime = `${hours}:${minutes} ${period}`;
        return `${formattedDate}, ${formattedTime}`;
    }

    const removeUnderScore = (str) => {

        let result = str.replace(/__c/g, " ");
        result = result.replace(/_/g, " ");
        return result;

    }
    const setCommentLoadeBegin = (data) => {
        setLoaderForNewComment(data)
    }

    const childFetchProjectRelativeTask = useCallback(async (id) => {
        setChildRelatedTaskLoader(true);
        setChildRelatedTask([])
        try {
            const res = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/getEntityrelativeTask/${id}`);
            const resData = await res.json();
            setChildRelatedTask(resData.records);
        } catch (err) {
            setChildRelatedTaskLoader(false);
            setMessageWithTimeout('Failed to fetch project relative tasks', 'error');
            console.error('Error fetching relative tasks:', err);
        } finally {
            setChildRelatedTaskLoader(false);
        }
    }, []);

    const childFetchProjectComments = useCallback(async (id) => {
        setChildCommentLoader(true);
        setChildComment([])
        try {
            const res = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/getEntitycomments/${id}`);
            const resData = await res.json();
            setChildComment(resData.records);
        } catch (err) {
            setChildCommentLoader(false);
            setMessageWithTimeout('Failed to fetch project comments', 'error');
            console.error('Error fetching comments:', err);
        } finally {
            setChildCommentLoader(false);
        }
    }, []);

    const childFetchProjectHistory = useCallback(async (id) => {
        setChildHistoryLoader(true);
        setChildHistory([])
        try {
            const res = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/getEntityHistoryByTaskId/${id}`);
            const resData = await res.json();
            setChildHistory(resData.records);
        } catch (err) {
            setChildHistoryLoader(false);
            console.error('Error fetching history:', err);
        } finally {
            setChildHistoryLoader(false);
        }
    }, []);

    const childFetchProjectDetail = useCallback(async (id) => {

        if (id == '') return null;
        setChildLoaderFun(true)
        setChildProjectData([])
        setChildHistory([])
        setChildComment([])
        setChildRelatedTask([])
        try {
            const res = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/EntityDetails/${localStorage.getItem('contactUserId')}/${id}`);
            const resData = await res.json();
            setChildProjectData(resData.records);
            setChildLoaderFun(false);
            const project = resData.records[0]
            if (
                ['Development', 'Solution Design', 'Unit Testing', 'Review', 'Deployment'].some(prefix => project.Title__c.startsWith(prefix)) &&
                project.RecordType.Name === 'Task' && project.Parent_Entity__r.RecordType.Name === "User Story"
            ) {
             
                setchilFirstConditonOfTaskChild(true);
                SetChildActiveTabCommentOrHistory('history')
            }
            else {
                setchilFirstConditonOfTaskChild(false);
            }
            await childFetchProjectRelativeTask(id);
            await childFetchProjectComments(id);
            await childFetchProjectHistory(id);
        } catch (err) {
            setMessageWithTimeout('Failed to fetch project data', 'error');
            console.error('Error fetching project details:', err);
        } finally {
            setChildLoaderFun(false);
        }
    }, [childFetchProjectRelativeTask, childFetchProjectComments, childFetchProjectHistory]);

    const childTaskCommentUpdate = (newComment) => {

        setChildComment(prevProjectData => {
            const newRecord = {
                "Id": newComment.Id,
                "Comment__c": newComment.content,
                "CreatedDate": new Date().toISOString(),
                "Commented_By__r": {
                    "Name": localStorage.getItem('contactUserName')
                }
            };

            if (!prevProjectData || !prevProjectData.length || !prevProjectData[0].Project_Entity_Commnets__r) {
                const updatedProject = {
                    ...prevProjectData[0],
                    Project_Entity_Commnets__r: {
                        records: [newRecord]
                    }
                };
                return [updatedProject];
            }

            const existingRecords = [...prevProjectData[0].Project_Entity_Commnets__r.records];
            existingRecords.unshift(newRecord);

            const updatedProject = {
                ...prevProjectData[0],
                Project_Entity_Commnets__r: {
                    ...prevProjectData[0].Project_Entity_Commnets__r,
                    records: existingRecords
                }
            };

            return [updatedProject];
        });
    };

    const SetChildActiveTabCommentOrHistory = (tab) => {
        setChildActiveTab(tab)
    }

    const statusOptions = [
        { value: "Defined", label: "Defined" },
        { value: "Ready", label: "Ready" },
        { value: "In Progress", label: "In Progress" },
        { value: "Draft", label: "Draft" },
        { value: "Review", label: "Review" },
        { value: "Blocked", label: "Blocked" },
        { value: "Demoed", label: "Demoed" },
        { value: "Prod", label: "Prod" },
        { value: "Closed", label: "Closed" }
    ];
    const handleStatusHistoryUpdate = async (id, selectedOption, oldOption) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/createHistory`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        Status__c: selectedOption.value,
                        Actual_Hours__c: null,
                        fieldApiName: "Status__c",
                        entityId: id,
                        newValue: selectedOption.value,
                        oldValue: oldOption,
                        conId: localStorage.getItem('contactUserId'),
                        objectApiName: "ANT_Project_Entity__c",
                    }),
                }
            );

            if (!response.ok) {
                throw new Error('Failed to update project history status');
            }
       
        } catch (error) {
            setMessageWithTimeout('Status history not updated', 'error');
            console.error('Error:', error);
        } finally {
            setSmallLoaderId(false);
        }
    };
    const handleStatusChange = async (id, selectedOption, index,oldOption) => {
        setStateClosed({ id: id, index: index })
        setSmallLoaderId(true);
        setEditedStatus(prevState => ({ ...prevState, [id]: selectedOption.value }));
        if (selectedOption.value.toLowerCase() === 'closed') {
            setWindowForActualHour(true);
        } else {
            try {

                const response = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/updateProjectEntityById/${id}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        Status__c: selectedOption.value,
                        Actual_Hours__c: null,
                    }),
                });
                if (!response.ok) {
                    setSmallLoaderId(false);
                    setMessageWithTimeout('Failed to update project status', 'error');
                    throw new Error('Failed to update project status');
                }
                await handleStatusHistoryUpdate(id, selectedOption, oldOption);
                           projectData[0].Status__c = selectedOption.value
                projectData[0].Actual_Hours__c = 0;
                setSmallLoaderId(false);
            } catch (error) {
                setSmallLoaderId(false);
                setMessageWithTimeout('Status not updated', 'error');
                console.error('Error:', error);
            }
        }
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: state.selectProps.value && state.selectProps.value.value === 'In Progress' ? '#d9f4ff' :
                state.selectProps.value && state.selectProps.value.value === 'Blocked' ? '#ffcaca' :
                    state.selectProps.value && state.selectProps.value.value === 'Review' ? '#fef0dd' :
                        state.selectProps.value && state.selectProps.value.value === 'Closed' ? '#d5ffee' :
                            state.selectProps.value && state.selectProps.value.value === 'Defined' ? '#f2f2f2' :
                                'white',
        }),
    };

    const handlWindowForActualHour = () => {
        setWindowForActualHour(!WindowForActualHour)
        setSmallLoaderId(false);
    }

    const handleSave = async (e) => {
        e.preventDefault();
        setSmallLoaderId(true);
        try {
            setWindowForActualHour(false);
            const response = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/updateProjectEntityById/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    Status__c: 'Closed',
                    Actual_Hours__c: Number(actualHours),
                }),
            });

            if (!response.ok) {
                setSmallLoaderId(null);
                setMessageWithTimeout('Failed to update project status', 'error');
                throw new Error('Failed to update project status');
            }
            await handleStatusHistoryUpdate(idStateClosed.id, closeTaskOldNewValue.newValue, closeTaskOldNewValue.oldValue);
            setSmallLoaderId(null);
            projectData[0].Status__c = 'Closed';
            projectData[0].Actual_Hours__c = Number(actualHours);
            setActualHours('');
            setCloseTaskOldNewValue({oldValue:'',newValue:''})
        } catch (error) {
            setSmallLoaderId(null);
            setMessageWithTimeout('Status not updated', 'error');
            console.error('Error:', error);
        }
    };

    const handleTimeEstimatedHoursChange = (value) => {
        const floatValue = parseFloat(value);
        setActualHours(floatValue);
    };
    const backFunctionHandel = () => {
        window.history.back();
    }
    const mobileViewSliderHandeler = () => {
       
        setSliderToggle(!sliderToggle)
    }

    return (
        <div style={{ position: 'relative' }}>
            <HrmNavbar />
            {messageFromBackend &&
                <div className="messageofHrm">
                    <div className={`${messageType}`}>
                        {messageFromBackend}
                    </div>
                </div>
            }
            {WindowForActualHour && (
                <form className="centered-window" onSubmit={handleSave}>
                    <div className='actualhourWindowCloseDiv' onClick={handlWindowForActualHour}>
                        <svg viewBox="0 0 24 24" fill="none" ><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="Menu / Close_MD"> <path id="Vector" d="M18 18L12 12M12 12L6 6M12 12L18 6M12 12L6 18" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g> </g></svg>
                    </div>
                    <div className="formForWindoDiv">
                        <label htmlFor="">Enter Actual Hours</label>
                        <input
                            type="number"
                            min="0"
                            placeholder="Enter actual hours"
                            className="timeCardTimeinput"
                            value={actualHours}
                            onChange={(e) => handleTimeEstimatedHoursChange(e.target.value)}
                            required
                            step="any"
                            id="hrmmytimeInput"
                        />
                        <button type="submit">Save</button>
                    </div>
                </form>
            )}
            {
                <div className='container' style={{ padding: "0rem", border: "1px solid #ccc", color: 'black', backgroundColor: 'rgb(250 250 250)', position: 'relative' }}>
                    <div style={{ position: 'relative' }}>


                        {loading ? (<></>) :
                            (
                                <ul className="tags" style={{ top: "45px", padding: "0.5rem" }}>

                                    <div className='innerTags '>
                                        <div className='assign-to-Div svgForTag'>
                                            {/* <button onClick={backFunctionHandel}>Goback</button> */}

                                            <div className='assignToLogoStyleNew'>
                                                <div className='backLogo'>
                                                    <svg
                                                        fill="#1011a2"
                                                        version="1.1"
                                                        id="Layer_1"
                                                        viewBox="0 0 330 330"
                                                        onClick={backFunctionHandel}
                                                        transform="rotate(180)"
                                                    >
                                                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                                        <g id="SVGRepo_iconCarrier">
                                                            <path
                                                                id="XMLID_27_"
                                                                d="M15,180h263.787l-49.394,49.394c-5.858,5.857-5.858,15.355,0,21.213C232.322,253.535,236.161,255,240,255
                                                                s7.678-1.465,10.606-4.394l75-75c5.858-5.857,5.858-15.355,0-21.213l-75-75c-5.857-5.857-15.355-5.857-21.213,0
                                                                c-5.858,5.857-5.858,15.355,0,21.213L278.787,150H15c-8.284,0-15,6.716-15,15S6.716,180,15,180z"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <svg viewBox="0 0 24 24" fill="none">
                                                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                                    <g id="SVGRepo_iconCarrier">
                                                        <path d="M12 2 A10 10 0 0 1 19.09 4.91"
                                                            stroke={getTypeColor(projectData[0]?.RecordType?.Name || '')}
                                                            stroke-width="2"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"></path>
                                                        <path d="M21.66 12 A10 10 0 0 1 19.09 19.09"
                                                            stroke={getTypeColor(projectData[0]?.RecordType?.Name || '')}
                                                            stroke-width="2"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"></path>
                                                        <path d="M12 21.66 A10 10 0 0 1 4.91 19.09"
                                                            stroke={getTypeColor(projectData[0]?.RecordType?.Name || '')}
                                                            stroke-width="2"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"></path>
                                                        <path d="M2.34 12 A10 10 0 0 1 4.91 4.91"
                                                            stroke={getTypeColor(projectData[0]?.RecordType?.Name || '')}
                                                            stroke-width="2"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"></path>
                                                        <circle cx="12" cy="12" r="6"
                                                            fill={getTypeColor(projectData[0]?.RecordType?.Name || '')}></circle>
                                                    </g>
                                                </svg>
                                                <div className='tooltipNewLeft'> {projectData[0]?.RecordType?.Name || ''}</div>

                                            </div>
                                        </div>
                                        <li className="tag">
                                            {projectData[0]?.Name || ''}
                                        </li>

                                    </div>

                                    <div className='rightSliderOpenDiv' style={{ zIndex: '9999' }}>
                                        <svg
                                            fill="rgb(16, 17, 162)"
                                            version="1.1"
                                            id="Capa_1"
                                            viewBox="0 0 220.682 220.682"
                                            xmlSpace="preserve"
                                            style={{ width: "1rem", height: "1rem", transform: sliderToggle ? "rotate(180deg)" : 'rotate(0deg)' }}
                                            onClick={mobileViewSliderHandeler}
                                        >
                                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                            <g id="SVGRepo_iconCarrier">
                                                <g>
                                                    <polygon points="92.695,38.924 164.113,110.341 92.695,181.758 120.979,210.043 220.682,110.341 120.979,10.639"></polygon>
                                                    <polygon points="28.284,210.043 127.986,110.341 28.284,10.639 0,38.924 71.417,110.341 0,181.758"></polygon>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                </ul>)}
                        {/* <div className='rightSliderOpenDiv' style={{ zIndex: '9999' }}>
                            <svg
                                fill="rgb(16, 17, 162)"
                                version="1.1"
                                id="Capa_1"
                                viewBox="0 0 220.682 220.682"
                                xmlSpace="preserve"
                                style={{ width: "1rem", height: "1rem", transform: sliderToggle ? "rotate(180deg)" : 'rotate(0deg)' }}
                                onClick={mobileViewSliderHandeler}
                            >
                                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <g>
                                        <polygon points="92.695,38.924 164.113,110.341 92.695,181.758 120.979,210.043 220.682,110.341 120.979,10.639"></polygon>
                                        <polygon points="28.284,210.043 127.986,110.341 28.284,10.639 0,38.924 71.417,110.341 0,181.758"></polygon>
                                    </g>
                                </g>
                            </svg>
                        </div> */}
                    </div>
                    <div style={{ display: "flex", width: "100%", position: "relative", backgroundColor: 'white' }}>
                        <div style={{ padding: '0.5rem 1rem', height: "calc(100vh - 120px)", backgroundColor: 'white' }} className={`scrollClassLeft rightDetailMainDivSection ${sliderToggle ? 'displayWidth' : ''}`}>
                            {loading ? (<LoaderForHrm loading={loading} />) :
                                (

                                    <div>
                                        <h3 className='colorClassHeading hrmHeadingStyle'> {projectData[0]?.Title__c
                                            || null} </h3>
                                    </div>
                                )
                            }


                            <h1 className='taskDetailDiscription colorClassHeading HrmsubHeading' style={{ fontWeight: 'bold' }}>Description:</h1>
                            <div style={{ marginTop: "1rem" }}>

                                {loading ? (null) :

                                    <div className='discriptionOfTaskDiv' style={{ marginBottom: '1rem' }}>
                                        <div dangerouslySetInnerHTML={{
                                            __html: projectData[0]?.Description__c || null
                                        }} style={{ fontSize: "1rem", overflowWrap: "anywhere" }} className="discriptionhtml" />


                                    </div>
                                }
                                {
                                    (relativeTask[0]?.Parent_Entity__r || relativeTask[0]?.ProjectEntities__r?.records) ? (
                                        <h1 className='taskDetailRealatedHeading HrmsubHeading colorClassHeading'>Related Task</h1>
                                    ) : null
                                }
                                {loadingSectionRelatedItem ? (
                                    <LoaderForHrm loading={loadingSectionRelatedItem} />

                                ) : (
                                    relativeTask[0]?.Parent_Entity__r || relativeTask[0]?.ProjectEntities__r?.records ? (
                                        <>

                                            <div className='EntityTreeDiv'>
                                                {

                                                    relativeTask[0]?.Parent_Entity__r ? (
                                                        <div className='chilEntityDiv' onClick={() => handelChildDetailId(relativeTask[0]?.Parent_Entity__r.Id || null)}>
                                                            <div className='innerchildTaskDetails'>
                                                                <div className='leftInnerchildTaskDetails'>
                                                                    <p className='taskDetailTaskId'>
                                                                        {relativeTask[0]?.Parent_Entity__r.Name ?? ""}
                                                                    </p>

                                                                    <p className='taskDetailTitle'>{relativeTask[0]?.Parent_Entity__r.Title__c ?? ""}</p>
                                                                </div>
                                                                <div>
                                                                    <p className='taskrecordType'>
                                                                        {relativeTask[0]?.Parent_Entity__r.Status__c ?? ""}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className='assign-to-Div'>
                                                                <div className='assignToLogoStyle'>
                                                                    {relativeTask[0]?.Parent_Entity__r.Assigned_To__r.Name ? relativeTask[0]?.Parent_Entity__r.Assigned_To__r.Name[0] : ""}
                                                                    <div className='tooltip2'>{relativeTask[0]?.Parent_Entity__r.Assigned_To__r.Name ? relativeTask[0]?.Parent_Entity__r.Assigned_To__r.Name : ""}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : null
                                                }
                                                {
                                                    (relativeTask[0]?.ProjectEntities__r?.records || []).map((item, index) => (
                                                        <div key={index} className='chilEntityDiv' onClick={() => handelChildDetailId(item.Id)}>
                                                            <div className='innerchildTaskDetails'>
                                                                <div className='leftInnerchildTaskDetails'>
                                                                    <p className='taskDetailTaskId'>
                                                                        {item.Name && item.Name}
                                                                    </p>
                                                                    <p className='taskDetailTitle'>
                                                                        {item.Title__c ?? ""}
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <p className='taskrecordType'>
                                                                        {item.Status__c ?? ""}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className='assign-to-Div'>
                                                                <div className='assignToLogoStyle'>
                                                                    {item.Assigned_To__r.Name ? item.Assigned_To__r.Name[0] : ""}
                                                                    <div className='tooltip2'>Assignee: {item.Assigned_To__r.Name ? item.Assigned_To__r.Name : ""}</div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    ))
                                                }
                                            </div>
                                        </>
                                    ) : null

                                )}
                                <div>
                                    <div style={{ display: 'flex', cursor: 'pointer' }}>
                                        {
                                            firstConditonOfTaskChild ? (<></>) : (<>
                                                <h6 onClick={() => ToggleHistoryCommentTab('comments')}
                                                    style={{
                                                        margin: '0rem 0.5rem 0.7rem 0',
                                                        borderBottom: activeTab === 'comments' ? '2px solid #1011a2' : 'none',
                                                        paddingBottom: '0.5rem',
                                                        marginRight: '1rem',
                                                        fontWeight: 'bold',
                                                    }} className='colorClassHeading HrmsubHeading'>Comments</h6>
                                            </>)}
                                        <h6 onClick={() => ToggleHistoryCommentTab('history')}
                                            style={{
                                                margin: '0rem 0px 0.7rem 0',
                                                fontWeight: 'bold',
                                                borderBottom: activeTab === 'history' ? '2px solid #1011a2' : 'none',
                                                paddingBottom: '0.5rem'
                                            }} className='colorClassHeading HrmsubHeading'>History</h6>
                                    </div>
                                    {activeTab === 'comments' && (
                                        <div>
                                            <div>
                                                {
                                                    firstConditonOfTaskChild ? (<></>) : (<>
                                                        {allUserOption.length > 0 && (
                                                            <TextEditor
                                                                allUserOption={allUserOption}
                                                                taskId={id}
                                                                updateProjectData={updateProjectComments}
                                                                setMessageWithTimeout={setMessageWithTimeout}
                                                                SetLoader={SetLoader}
                                                                setCommentLoadeBegin={setCommentLoadeBegin}
                                                            />
                                                        )}
                                                    </>)}
                                            </div>
                                            {loadingSectionComment ? (
                                                <LoaderForHrm loading={loadingSectionComment} />
                                            ) : (
                                                firstConditonOfTaskChild ? (
                                                    <></>
                                                ) : (
                                                    <>
                                                        {
                                                            projectComments && projectComments[0]?.Project_Entity_Commnets__r?.records && projectComments[0].Project_Entity_Commnets__r.records.map(commentItem => (
                                                                <div className="comment-card" key={commentItem.Id}>
                                                                    <div className='adminPanelMrm-discription-user-date-div'>
                                                                        <div>
                                                                            {loaderForNewComment.loading && commentItem.Id == loaderForNewComment.Id ? (
                                                                                <SmallLoader />
                                                                            ) : (
                                                                                <div className='circlLogoStyle'>
                                                                                    {commentItem.Commented_By__r ? commentItem.Commented_By__r.Name[0] : ""}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        <div style={{ marginLeft: "1rem", color: 'black' }}>
                                                                            <p style={{ fontWeight: "500", fontSize: "0.8rem", color: "", margin: '0', marginBottom: "0.3rem" }}>
                                                                                {commentItem.Commented_By__r ? commentItem.Commented_By__r.Name : ""}
                                                                            </p>
                                                                            <p style={{ fontSize: "0.8rem" }}>
                                                                                {newFormatDateTime(commentItem.CreatedDate ? commentItem.CreatedDate : '')}
                                                                            </p>
                                                                        </div>
                                                                    </div>

                                                                    <div dangerouslySetInnerHTML={{ __html: commentItem?.Comment__c || '' }}></div>
                                                                </div>
                                                            ))
                                                        }
                                                    </>
                                                ))}
                                        </div>
                                    )}
                                    {activeTab === 'history' && (
                                        <div>
                                            <ol className="activity-stream">
                                                {loadingSectionHistory ? (
                                                    <LoaderForHrm loading={loadingSectionHistory} />
                                                ) : (
                                                    newProjectHistory && newProjectHistory.map(item => (
                                                        <li>
                                                            <div className=" icon"><div className='historyAssignToLogoStyle'>B
                                                                <div className='historyTooltip2'>Assignee: Bajrang Mahawar</div>
                                                            </div></div>
                                                            <div className='historyCardDiv'>
                                                                <p className='heding-historyCard'>{removeUnderScore(item ? item.Field : '')}</p>
                                                                <div className='history-ChangesHeading'>
                                                                    <p>{item ? item.OldValue : ''}</p>
                                                                    {
                                                                        item.NewValue != null || item.OldValue != null ? (<p>
                                                                            <svg fill="#000000" version="1.1" id="Layer_1" viewBox="0 0 330 330" xmlSpace="preserve" transform="rotate(90)">
                                                                                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                                                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                                                                <g id="SVGRepo_iconCarrier">
                                                                                    <path id="XMLID_29_" d="M100.606,100.606L150,51.212V315c0,8.284,6.716,15,15,15c8.284,0,15-6.716,15-15V51.212l49.394,49.394 
            C232.322,103.535,236.161,105,240,105c3.839,0,7.678-1.465,10.606-4.394c5.858-5.857,5.858-15.355,0-21.213l-75-75 
            c-5.857-5.858-15.355-5.858-21.213,0l-75,75c-5.858,5.857-5.858,15.355,0,21.213C85.251,106.463,94.749,106.463,100.606,100.606z"></path>
                                                                                </g>
                                                                            </svg>
                                                                        </p>) : null
                                                                    }
                                                                    <p >{item ? item.NewValue : ''}</p>
                                                                </div>
                                                                <p className='historyTimeDateP'>{newFormatDateTime(item ? item.CreatedDate : '')}</p>
                                                            </div>
                                                        </li>
                                                    ))
                                                )}
                                            </ol>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={`scrollClassLeft leftDetailMainDivSection ${sliderToggle ? 'widthScreen' : ''}`} style={{ backgroundColor: 'white' }}>
                            {loading ? (
                                <LoaderForHrm loading={loading} />) : (
                                <div className='' >
                                    <div className='innerGridTaskDetail1'>
                                        <div style={{ marginBottom: "0.5rem" }}>  {
                                            smallLoaderId ? (
                                                <SmallLoader />
                                            ) : (
                                                <Select
                                                    value={statusOptions.find(option => option.value === (editedStatus[id] || projectData[0]?.Status__c || ''
                                                    ))}
                                                    onChange={(selectedOption) => { handleStatusChange(id, selectedOption, id,projectData[0]?.Status__c) }}
                                                    options={statusOptions}
                                                    defaultValue={projectData[0]?.Status__c || ''}
                                                    styles={customStyles}
                                                    style={{ color: "black !important" }}
                                                />

                                            )}
                                        </div>
                                        {/* <div style={{ marginBottom: "0.5rem" }}>
                                            {smallLoaderId ? (
                                                <SmallLoader />
                                            ) : (
                                                <Select
                                                    value={statusOptions.find(option => option.value === (editedStatus[id] || (projectData[0]?.Status__c || '')))}
                                                    onChange={(selectedOption) => { handleStatusChange(id, selectedOption, id) }}
                                                    options={statusOptions}
                                                    defaultValue={projectData[0]?.Status__c || ''}
                                                    styles={customStyles}
                                                    style={{ color: "black !important" }}
                                                />
                                            )}
                                        </div> */}

                                        <div style={{}} className='centerdueDateDiv1'>
                                            <h6 style={{ fontSize: "0.8rem", }}>Project:</h6>
                                            <h4 style={{ fontSize: "0.8rem" }}> {projectData[0]?.Project__r.Title__c
                                                || null} </h4>
                                        </div>
                                        <div className='centerdueDateDiv1'>
                                            <h6 style={{ fontSize: "0.8rem" }}>Reporter:</h6>
                                            <div style={{ display: "flex", gap: "0.3rem" }}>
                                                <div className='assignToLogoStyleNew'>
                                                    <div className='nameShowDiv'> {projectData[0]?.Reporter__r && projectData[0].Reporter__r.Name
                                                            ? projectData[0].Reporter__r.Name[0] 
                                                            : 'N'}</div>
                                                    <div className='tooltipNewLeft'>{projectData[0]?.Reporter__r && projectData[0].Reporter__r.Name
                                                            ? projectData[0].Reporter__r.Name 
                                                            : 'Null'}</div>
                                                </div>
                                                <h4 style={{ color: "", fontSize: '0.8rem' }}>{projectData[0]?.Reporter__r && projectData[0].Reporter__r.Name
                                                        ? projectData[0].Reporter__r.Name 
                                                        : 'Null'}</h4>
                                            </div>
                                        </div>
                                        
                                        <div className='centerdueDateDiv1'>
                                            <h6 style={{ fontSize: "0.8rem", }}>Assignee:</h6>
                                            <div style={{ display: "flex", gap: "0.3rem" }}>
                                                <div className='assignToLogoStyleNew'>
                                                    <div className='nameShowDiv'>{projectData[0]?.Assigned_To__r.Name[0]
                                                        || null}</div>
                                                    <div className='tooltipNewLeft'> {projectData[0]?.Assigned_To__r.Name || ''}</div>
                                                </div>
                                                <h4 style={{ fontSize: "0.8rem" }}>{projectData[0]?.Assigned_To__r.Name || ''}</h4>
                                            </div>

                                        </div>
                                        <div style={{}} className='centerdueDateDiv1'>
                                            <h6 style={{ fontSize: "0.8rem", }}>Estimates:</h6>
                                            <h4 style={{ fontSize: "0.9rem" }}>{projectData[0]?.Estimated_Hours__c || 0}</h4>
                                        </div>

                                        <div className='centerdueDateDiv1'>
                                            <h6 style={{ fontSize: "0.8rem", }}>Actual:</h6>
                                            <h4 style={{ fontSize: "0.9rem" }}>{projectData[0]?.Actual_Hours__c || 0}</h4>
                                        </div>
                                        <div className='centerdueDateDiv1'>
                                            <h6 style={{ fontSize: "0.8rem" }}>Due:</h6>
                                            <h4 style={{ color: "", fontSize: '0.8rem' }}>{formatDateTime(projectData[0]?.CreatedDate || null)}</h4>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            }
            {childTaskId ? (
                <TaskDetailView
                    selectedId={childTaskId}
                    projectData={childProjectData}
                    allUserOption={allUserOption}
                    handleIdClick={handelChildDetailId}
                    handelClosedBtnKanbanDetails={handelChildDetailId}
                    updateProjectData={childTaskCommentUpdate}
                    projectHistory={projectHistory}
                    SetLoader={SetLoader}
                    loading={childLoading}
                    relativeTask={childRelatedTask}
                    newProjectHistory={childHistory}
                    projectComments={childComment}
                    loadingSectionRelatedItem={childRelatedTaskLoader}
                    loadingSectionComment={childCommentLoader}
                    loadingSectionHistory={childHistoryLoader}
                    SetActiveTabCommentOrHistory={SetChildActiveTabCommentOrHistory}
                    activeTab={childActiveTab}
                    firstConditonOfTaskChild={chilFirstConditonOfTaskChild}
                />
            ) : null}
        </div>
    );
}
