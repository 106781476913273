import React, { useState, useCallback,useEffect } from 'react'
import TaskDetailView from './TaskDetailView';
import './css/historyCss.css'
import TextEditor from './TextEditor';
import SmallLoader from './SmallLoader';
import LoaderForHrm from './LoaderForHrm';
export default function TaskDetailViewForRight({ selectedId, projectData, allUserOption, updateProjectData, SetLoader, setMessageWithTimeout, projectHistory, loading, newProjectHistory, projectComments, relativeTask, loadingSectionRelatedItem, loadingSectionComment, loadingSectionHistory, handelClosedBtnKanbanDetails,activeTab,SetActiveTabCommentOrHistory, firstConditonOfTaskChild}) {
    const [childProjectData, setChildProjectData] = useState([]);
    const [childTaskId, setChildTaskId] = useState('')
    const [loaderForNewComment, setLoaderForNewComment] = useState({ loading: true, Id: null })
    const [childLoading, setChildLoading] = useState(false)
    const [childHistory, setChildHistory] = useState([]);
    const [childRelatedTask, setChildRelatedTask] = useState([]);
    const [childComment, setChildComment] = useState([])
    const [childHistoryLoader, setChildHistoryLoader] = useState([]);
    const [childRelatedTaskLoader, setChildRelatedTaskLoader] = useState([]);
    const [childCommentLoader, setChildCommentLoader] = useState([])
    const [childActiveTab, setChildActiveTab] = useState('comments');
    const [chilFirstConditonOfTaskChild, setchilFirstConditonOfTaskChild] = useState(false);
    const setChildLoaderFun = (data) => {
        setChildLoading(data)
    }

    const fetchProjectRelativeTask = useCallback(async (id) => {
        setChildRelatedTaskLoader(true);
        setChildRelatedTask([])
        try {
            const res = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/getEntityrelativeTask/${id}`);
            const resData = await res.json();
            setChildRelatedTask(resData.records)
        } catch (err) {
            setMessageWithTimeout('Failed to fetch project relative tasks', 'error');
            console.error('Error fetching relative tasks:', err);
        } finally {
            setChildRelatedTaskLoader(false);
        }
    }, []);

    const fetchProjectComments = useCallback(async (id) => {
        setChildCommentLoader(true);
        setChildComment([])
        try {
            const res = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/getEntitycomments/${id}`);
            const resData = await res.json();
            setChildComment(resData.records);
        } catch (err) {
            setMessageWithTimeout('Failed to fetch project comments', 'error');
            console.error('Error fetching comments:', err);
        } finally {
            setChildCommentLoader(false);
        }
    }, []);
    

    const fetchProjectHistory = useCallback(async (id) => {
        setChildHistoryLoader(true);
        setChildHistory([])
        try {
            const res = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/getEntityHistoryByTaskId/${id}`);
            const resData = await res.json();
            setChildHistory(resData.records);
        } catch (err) {
            console.error('Error fetching history:', err);
        } finally {
            setChildHistoryLoader(false);
        }
    }, []);

    const fetchProjectDetail = useCallback(async (id) => {
        if (id == '') return null;
        setChildLoaderFun(true)
        setChildProjectData([])
        setChildRelatedTask([])
        setChildComment([])
        setChildHistory([])
        try {
            const res = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/EntityDetails/${localStorage.getItem('contactUserId')}/${id}`);
            const resData = await res.json();
            setChildProjectData(resData.records);
            setMessageWithTimeout('Successfully fetched project data', 'success');
            setChildLoaderFun(false);
            const project=resData.records[0]
            if (
                ['Development', 'Solution Design', 'Unit Testing', 'Review','Deployment'].some(prefix => project.Title__c.startsWith(prefix)) &&
                project.RecordType.Name === 'Task' && project.Parent_Entity__r.RecordType.Name === "User Story"
            ) {
                setchilFirstConditonOfTaskChild(true);
                SetChildActiveTabCommentOrHistory('history')
            }
            else{
                setchilFirstConditonOfTaskChild(false);
            }
            await fetchProjectRelativeTask(id);
            await fetchProjectComments(id);
            await fetchProjectHistory(id);
        } catch (err) {
            setMessageWithTimeout('Failed to fetch project data', 'error');
            console.error('Error fetching project details:', err);
        } finally {
            setChildLoaderFun(false);
        }
    }, [fetchProjectRelativeTask, fetchProjectComments, fetchProjectHistory]);

    const getTypeColor = (item) => {
        switch (item) {
            case 'Bug':
                return 'red';
            case 'User Story':
                return 'green';
            case 'Task':
                return 'blue';
            default:
                return 'violet';
        }
    }
    const formatDateTime = (dateTimeString) => {
        if (!dateTimeString) {
            return "";
        }
        const date = new Date(dateTimeString);
        const day = date.getDate();
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();
        const formattedDate = `${month} ${day} ${year}`;
        return formattedDate;
    }
    function newFormatDateTime(dateTimeString) {
        const dateTime = new Date(dateTimeString);
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = months[dateTime.getMonth()];
        const day = dateTime.getDate().toString().padStart(2, '0');
        const year = dateTime.getFullYear();
        const weekday = dateTime.toLocaleString('en-GB', { weekday: 'short' });
        const formattedDate = `${day} ${month} ${year}`;
        let hours = dateTime.getHours();
        const minutes = dateTime.getMinutes().toString().padStart(2, '0');
        const period = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        const formattedTime = `${hours}:${minutes} ${period}`;
        return `${formattedDate}, ${formattedTime}`;
    }

    const handelChildDetailId = (id) => {
        setChildTaskId(id === childTaskId ? '' : id);
        SetChildActiveTabCommentOrHistory('comments')
        fetchProjectDetail(id);
    }

    const setCommentLoadeBegin = (data) => {
        setLoaderForNewComment(data)
    }
    const openNewTabWindow = (id) => {
        const url = `/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/adminPanelHrm/task-detail/?id=${id}`;
        window.open(url, '_blank');
    }
    const removeUnderScore = (str) => {
        let result = str.replace(/__c/g, " ");
        result = result.replace(/_/g, " ");
        return result;
    }
    const childTaskCommentUpdate = (newComment) => {
        setChildComment(prevProjectData => {
            const newRecord = {
                "Id": newComment.Id,
                "Comment__c": newComment.content,
                "CreatedDate": new Date().toISOString(),
                "Commented_By__r": {
                    "Name": localStorage.getItem('contactUserName')
                }
            };

            if (!prevProjectData || !prevProjectData.length || !prevProjectData[0].Project_Entity_Commnets__r) {
                const updatedProject = {
                    ...prevProjectData[0],
                    Project_Entity_Commnets__r: {
                        records: [newRecord]
                    }
                };
                return [updatedProject];
            }

            const existingRecords = [...prevProjectData[0].Project_Entity_Commnets__r.records];
            existingRecords.unshift(newRecord);

            const updatedProject = {
                ...prevProjectData[0],
                Project_Entity_Commnets__r: {
                    ...prevProjectData[0].Project_Entity_Commnets__r,
                    records: existingRecords
                }
            };

            return [updatedProject];
        });
    };
    const SetChildActiveTabCommentOrHistory = (tab) => {
        setChildActiveTab(tab)
    }

    return (
        <>
            {
                <div className='adminPanelMrm-card-detail-div container' style={{ width: "50%", border: "1px solid #ccc", padding: '0', color: 'black', backgroundColor: 'rgb(250 250 250)' }}>
                    <div>
                        <div></div>
                        {loading ? (<></>) :
                            (
                                <ul className="tags">
                                    <div className='innerTags'>
                                        <div className='assign-to-Div svgForTag'>
                                            <div className='assignToLogoStyleNew'>
                                                <svg viewBox="0 0 24 24" fill="none">
                                                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                                    <g id="SVGRepo_iconCarrier">
                                                        <path d="M12 2 A10 10 0 0 1 19.09 4.91"
                                                            stroke={getTypeColor(projectData[0]?.RecordType?.Name || '')}
                                                            stroke-width="2"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"></path>
                                                        <path d="M21.66 12 A10 10 0 0 1 19.09 19.09"
                                                            stroke={getTypeColor(projectData[0]?.RecordType?.Name || '')}
                                                            stroke-width="2"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"></path>
                                                        <path d="M12 21.66 A10 10 0 0 1 4.91 19.09"
                                                            stroke={getTypeColor(projectData[0]?.RecordType?.Name || '')}
                                                            stroke-width="2"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"></path>
                                                        <path d="M2.34 12 A10 10 0 0 1 4.91 4.91"
                                                            stroke={getTypeColor(projectData[0]?.RecordType?.Name || '')}
                                                            stroke-width="2"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"></path>
                                                        <circle cx="12" cy="12" r="6"
                                                            fill={getTypeColor(projectData[0]?.RecordType?.Name || '')}></circle>
                                                    </g>
                                                </svg>
                                                <div className='tooltipNewLeft'> {projectData[0]?.RecordType?.Name || ''}</div>
                                            </div>
                                        </div>
                                        <li className="tag">
                                            {projectData[0]?.Name || ''}
                                        </li>
                                    </div>
                                    <div className='innerTags'>

                                        <li className='assign-to-Div svgForTag'>
                                            <div className='assignToLogoStyleNew' onClick={() => openNewTabWindow(projectData[0].Id)}>
                                                <svg
                                                    fill="rgb(16, 17, 162)"
                                                    viewBox="0 0 64 64"
                                                    version="1.1"
                                                    style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2, width: "2rem", height: "2rem" }}
                                                >
                                                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                                    <g id="SVGRepo_iconCarrier">
                                                        <rect id="Icons" x="-896" y="0" width="1280" height="800" style={{ fill: 'none' }}></rect>
                                                        <g id="Icons1" serifId="Icons"><g id="Strike"></g><g id="H1"></g><g id="H2"></g><g id="H3"></g><g id="list-ul"></g><g id="hamburger-1"></g><g id="hamburger-2"></g><g id="list-ol"></g><g id="list-task"></g><g id="trash"></g><g id="vertical-menu"></g><g id="horizontal-menu"></g><g id="sidebar-2"></g><g id="Pen"></g><g id="Pen1" serifId="Pen"></g><g id="clock"></g><g id="external-link">
                                                            <path d="M36.026,20.058l-21.092,0c-1.65,0 -2.989,1.339 -2.989,2.989l0,25.964c0,1.65 1.339,2.989 2.989,2.989l26.024,0c1.65,0 2.989,-1.339 2.989,-2.989l0,-20.953l3.999,0l0,21.948c0,3.308 -2.686,5.994 -5.995,5.995l-28.01,0c-3.309,0 -5.995,-2.687 -5.995,-5.995l0,-27.954c0,-3.309 2.686,-5.995 5.995,-5.995l22.085,0l0,4.001Z"></path>
                                                            <path d="M55.925,25.32l-4.005,0l0,-10.481l-27.894,27.893l-2.832,-2.832l27.895,-27.895l-10.484,0l0,-4.005l17.318,0l0.002,0.001l0,17.319Z"></path>
                                                        </g><g id="hr"></g><g id="info"></g><g id="warning"></g><g id="plus-circle"></g><g id="minus-circle"></g><g id="vue"></g><g id="cog"></g><g id="logo"></g><g id="radio-check"></g><g id="eye-slash"></g><g id="eye"></g><g id="toggle-off"></g><g id="shredder"></g><g id="spinner--loading--dots-" serifId="spinner [loading, dots]"></g><g id="react"></g><g id="check-selected"></g><g id="turn-off"></g><g id="code-block"></g><g id="user"></g><g id="coffee-bean"></g><g id="coffee-beans">
                                                                <g id="coffee-bean1" serifId="coffee-bean"></g>
                                                            </g><g id="coffee-bean-filled"></g><g id="coffee-beans-filled">
                                                                <g id="coffee-bean2" serifId="coffee-bean"></g>
                                                            </g><g id="clipboard"></g><g id="clipboard-paste"></g><g id="clipboard-copy"></g><g id="Layer1"></g>
                                                        </g>
                                                    </g>
                                                </svg>
                                                <div className='tooltipNewLeftNewRight'>New Tab</div>
                                            </div>
                                        </li>
                                        <li className='assign-to-Div svgForTag'>
                                            <div className='assignToLogoStyleNew' onClick={() => handelClosedBtnKanbanDetails()}>

                                                <svg
                                                    fill="rgb(16, 17, 162)"
                                                    version="1.1"
                                                    id="Capa_1"
                                                    viewBox="0 0 220.682 220.682"
                                                    xmlSpace="preserve"
                                                    style={{ width: "1.5rem", height: "1.5rem" }}
                                                >
                                                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                                    <g id="SVGRepo_iconCarrier">
                                                        <g>
                                                            <polygon points="92.695,38.924 164.113,110.341 92.695,181.758 120.979,210.043 220.682,110.341 120.979,10.639"></polygon>
                                                            <polygon points="28.284,210.043 127.986,110.341 28.284,10.639 0,38.924 71.417,110.341 0,181.758"></polygon>
                                                        </g>
                                                    </g>
                                                </svg>
                                                <div className='tooltipNewLeftNewRight'>Close Detail View</div>
                                            </div>
                                        </li>
                                    </div>
                                </ul>
                            )}

                        <div style={{ padding: '0.5rem 1rem' }}>
                            {loading ? (<LoaderForHrm loading={loading} />) :
                                (
                                    <div>
                                        <h3 style={{ fontSize: "1.5rem", margin: 0, fontWeight: 'bold' }} className='colorClassHeading'> {projectData[0]?.Title__c
                                            || null} </h3>

                                        <div className='grid_detail_of_Task'>
                                            <div className='innerGridTaskDetail1'>
                                                <div style={{}} className='dueDateDiv1'>
                                                    <h6 style={{ fontSize: "1rem", }}>Project:</h6>
                                                    <h4 style={{ fontSize: "1rem" }}> {projectData[0]?.Project__r.Title__c
                                                        || null} </h4>
                                                </div>
                                                <div className='dueDateDiv1'>
                                                    <h6 style={{ fontSize: "1rem" }}>Reporter:</h6>
                                                    <h4 style={{ color: "", fontSize: '0.9rem' }}>{projectData[0]?.Reporter__r && projectData[0].Reporter__r.Name
                                                        ? projectData[0].Reporter__r.Name 
                                                        : 'Null'}</h4>
                                                </div>
                                                <div className='dueDateDiv1'>
                                                    <h6 style={{ fontSize: "1rem", }}>Assignee:</h6>
                                                    <h4 style={{ fontSize: "1rem" }}>{projectData[0]?.Assigned_To__r.Name || ''}</h4>

                                                </div>
                                            </div >
                                            <div className='innerGridTaskDetail'>
                                                <div style={{}} className='dueDateDiv'>
                                                    <h6 style={{ fontSize: "1rem", }}>Estimates:</h6>
                                                    <h4 style={{ fontSize: "1rem" }}>{projectData[0]?.Estimated_Hours__c || 0}</h4>
                                                </div>

                                                <div className='dueDateDiv'>
                                                    <h6 style={{ fontSize: "1rem", }}>Actual:</h6>
                                                    <h4 style={{ fontSize: "1rem" }}>{projectData[0]?.Actual_Hours__c || 0}</h4>
                                                </div>
                                                <div className='dueDateDiv'>
                                                    <h6 style={{ fontSize: "1rem" }}>Due:</h6>
                                                    <h4 style={{ color: "", fontSize: '0.9rem' }}>{formatDateTime(projectData[0]?.CreatedDate || null)}</h4>
                                                </div>
                                            </div>


                                        </div>

                                    </div>
                                )}

                            <h1 className='taskDetailDiscription colorClassHeading' style={{ fontWeight: 'bold' }}>Description:</h1>
                            <div style={{ marginTop: "1rem" }}>
                                {loading ? (null) :

                                    <div className='discriptionOfTaskDiv'>
                                        <div dangerouslySetInnerHTML={{
                                            __html: projectData[0]?.Description__c || null
                                        }} style={{ fontSize: "1rem", overflowWrap: "anywhere" }} className="discriptionhtml" />

                                    </div>

                                }
                                {
                                    (relativeTask[0]?.Parent_Entity__r || relativeTask[0]?.ProjectEntities__r?.records) ? (
                                        <h1 className='taskDetailRealatedHeading colorClassHeading' style={{ fontWeight: 'bold' }}>Related Task</h1>
                                    ) : null
                                }
                                {loadingSectionRelatedItem ? (
                                    <LoaderForHrm loading={loadingSectionRelatedItem} />
                                ) : (
                                    relativeTask[0]?.Parent_Entity__r || relativeTask[0]?.ProjectEntities__r?.records ? (
                                        <>

                                            <div className='EntityTreeDiv'>
                                                {
                                                    relativeTask[0]?.Parent_Entity__r ? (
                                                        <div className='chilEntityDiv' onClick={() => handelChildDetailId(relativeTask[0]?.Parent_Entity__r.Id || null)}>
                                                            <div className='innerchildTaskDetails'>
                                                                <div className='leftInnerchildTaskDetails'>
                                                                    <p className='taskDetailTaskId'>
                                                                        {relativeTask[0]?.Parent_Entity__r.Name}
                                                                    </p>

                                                                    <p className='taskDetailTitle'>{relativeTask[0]?.Parent_Entity__r.Title__c}</p>
                                                                </div>
                                                                <div>
                                                                    <p className='taskrecordType'>
                                                                        {relativeTask[0]?.Parent_Entity__r.Status__c}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className='assign-to-Div'>
                                                                <div className='assignToLogoStyle'>
                                                                    {relativeTask[0]?.Parent_Entity__r.Assigned_To__r.Name ? relativeTask[0]?.Parent_Entity__r.Assigned_To__r.Name[0] : ""}
                                                                    <div className='tooltip2'>{relativeTask[0]?.Parent_Entity__r.Assigned_To__r.Name ? relativeTask[0]?.Parent_Entity__r.Assigned_To__r.Name : ""}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (<></>)
                                                }
                                                {
                                                    (relativeTask[0]?.ProjectEntities__r?.records || []).map((item, index) => (
                                                        <div key={index} className='chilEntityDiv' onClick={() => handelChildDetailId(item.Id)}>
                                                            <div className='innerchildTaskDetails'>
                                                                <div className='leftInnerchildTaskDetails'>
                                                                    <p className='taskDetailTaskId'>
                                                                        {item.Name && item.Name}
                                                                    </p>
                                                                    <p className='taskDetailTitle'>
                                                                        {item.Title__c && item.Title__c}
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <p className='taskrecordType'>
                                                                        {item.Status__c && item.Status__c}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className='assign-to-Div'>
                                                                <div className='assignToLogoStyle'>
                                                                    {item.Assigned_To__r.Name ? item.Assigned_To__r.Name[0] : ""}
                                                                    <div className='tooltip2'>Assignee: {item.Assigned_To__r.Name ? item.Assigned_To__r.Name : ""}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </>
                                    ) : null
                                )
                                }

                                <div style={{ display: 'flex', cursor: 'pointer', marginTop: '0.6rem' }}>
                                    {
                                        firstConditonOfTaskChild ? (<></>) : (<>
                                            <h6 onClick={() => SetActiveTabCommentOrHistory('comments')}
                                                style={{
                                                    margin: '0rem 0.5rem 0.7rem 0',
                                                    fontSize: '1rem',
                                                    borderBottom: activeTab === 'comments' ? '2px solid #1011a2' : 'none',
                                                    paddingBottom: '0.5rem',
                                                    marginRight: '1rem',
                                                    fontWeight: 'bold',
                                                }} className='colorClassHeading'>Comments</h6>
                                        </>)

                                    }
                                    <h6 onClick={() => SetActiveTabCommentOrHistory('history')}
                                        style={{
                                            margin: '0rem 0px 0.7rem 0',
                                            fontSize: '1rem',
                                            fontWeight: 'bold',
                                            borderBottom: activeTab === 'history' ? '2px solid #1011a2' : 'none',
                                            paddingBottom: '0.5rem'
                                        }} className='colorClassHeading'>History</h6>
                                </div>
                                {activeTab === 'comments' && (
                                    <div>
                                        <div>{
                                            firstConditonOfTaskChild ? (<></>) : (<>
                                                <TextEditor allUserOption={allUserOption} taskId={selectedId} updateProjectData={updateProjectData} SetLoader={SetLoader} setMessageWithTimeout={setMessageWithTimeout} setCommentLoadeBegin={setCommentLoadeBegin} />
                                            </>)

                                        }

                                        </div>
                                        {loadingSectionComment ? (
                                            <LoaderForHrm loading={loadingSectionComment} />
                                        ) :
                                       
                                        (
                                            firstConditonOfTaskChild ? (
                                                <></>
                                            ) : (
                                                <>
                                                    {projectComments && projectComments[0]?.Project_Entity_Commnets__r?.records && projectComments[0].Project_Entity_Commnets__r.records.map(commentItem => (
                                                        <div className="comment-card" key={commentItem.Id}>
                                                            <div className='adminPanelMrm-discription-user-date-div'>
                                                                <div>
                                                                    {loaderForNewComment.loading && commentItem.Id === loaderForNewComment.Id ? (
                                                                        <SmallLoader />
                                                                    ) : (
                                                                        <div className='circlLogoStyle'>
                                                                            {commentItem.Commented_By__r ? commentItem.Commented_By__r.Name[0] : ""}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div style={{ marginLeft: "1rem", color: 'black' }}>
                                                                    <p style={{ fontWeight: "500", fontSize: "0.8rem", color: "", margin: '0', marginBottom: "0.3rem" }}>
                                                                        {commentItem.Commented_By__r ? commentItem.Commented_By__r.Name : ""}
                                                                    </p>
                                                                    <p style={{ fontSize: "0.8rem" }}>
                                                                        {newFormatDateTime(commentItem.CreatedDate ? commentItem.CreatedDate : '')}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div dangerouslySetInnerHTML={{ __html: commentItem?.Comment__c || '' }}></div>
                                                        </div>
                                                    ))}
                                                </>
                                            )
                                        )
                                        }
                                    </div>
                                )}
                                {activeTab === 'history' && (
                                    <div>
                                        <ol className="activity-stream">
                                            {loadingSectionHistory ? (
                                                <LoaderForHrm loading={loadingSectionHistory} />
                                            ) : (
                                                newProjectHistory && newProjectHistory.map(item => (
                                                    <li>
                                                        <div className=" icon"><div className='historyAssignToLogoStyle'>{projectData[0]?.Assigned_To__r.Name[0] || ''}
                                                            <div className='historyTooltip2'>{projectData[0]?.Assigned_To__r.Name || ''}</div>
                                                        </div></div>
                                                        <div className='historyCardDiv'>
                                                            <p className='heding-historyCard'>{removeUnderScore(item ? item.Field : '')}</p>
                                                            <div className='history-ChangesHeading'>
                                                                <p>{item ? item.OldValue : ''}</p>
                                                                {
                                                                    item.NewValue != null || item.OldValue != null ? (<p>
                                                                        <svg fill="#000000" version="1.1" id="Layer_1" viewBox="0 0 330 330" xmlSpace="preserve" transform="rotate(90)">
                                                                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                                                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                                                            <g id="SVGRepo_iconCarrier">
                                                                                <path id="XMLID_29_" d="M100.606,100.606L150,51.212V315c0,8.284,6.716,15,15,15c8.284,0,15-6.716,15-15V51.212l49.394,49.394 
    C232.322,103.535,236.161,105,240,105c3.839,0,7.678-1.465,10.606-4.394c5.858-5.857,5.858-15.355,0-21.213l-75-75 
    c-5.857-5.858-15.355-5.858-21.213,0l-75,75c-5.858,5.857-5.858,15.355,0,21.213C85.251,106.463,94.749,106.463,100.606,100.606z"></path>
                                                                            </g>
                                                                        </svg>
                                                                    </p>) : null
                                                                }
                                                                <p >{item ? item.NewValue : ''}</p>
                                                            </div>
                                                            <p className='historyTimeDateP'>{newFormatDateTime(item ? item.CreatedDate : '')}</p>
                                                        </div>
                                                    </li>
                                                ))
                                            )}
                                        </ol>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            }
            {childTaskId ? (
                <TaskDetailView
                    selectedId={childTaskId}
                    projectData={childProjectData}
                    handleIdClick={handelChildDetailId}
                    allUserOption={allUserOption}
                    handelClosedBtnKanbanDetails={handelChildDetailId}
                    updateProjectData={childTaskCommentUpdate}
                    SetLoader={setChildLoaderFun}
                    setMessageWithTimeout={setMessageWithTimeout}
                    projectHistory={projectHistory}
                    loading={childLoading}
                    relativeTask={childRelatedTask}
                    newProjectHistory={childHistory}
                    projectComments={childComment}
                    loadingSectionRelatedItem={childRelatedTaskLoader}
                    loadingSectionComment={childCommentLoader}
                    loadingSectionHistory={childHistoryLoader}
                    SetActiveTabCommentOrHistory={SetChildActiveTabCommentOrHistory}
                    activeTab={childActiveTab}
                    firstConditonOfTaskChild={chilFirstConditonOfTaskChild}
                />
            ) : null}
        </>
    )
}
