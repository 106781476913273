import React, { Component } from 'react';
import Input from '../../components/Form/Input/Input';
import Button from '../../components/Button/Button';
import Auth from '../Auth/Auth';
import Loader from '../../components/Loader/Loader';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Post from '../../components/Feed/Post/Post';
class EmployeeManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editorState: EditorState.createEmpty(),
            formData: [],
            submitBtn: false,
            caseStudyForm: {
                name: {
                    value: '',
                    valid: false,
                    validators: [],
                },
                email: {
                    value: '',
                    valid: false,
                    validators: [this.validateEmail],
                },
                password: {
                    value: '123456',
                    valid: false,
                },
                employeeSalesforceId: {
                    value: '',
                    valid: false,
                    validators: [],
                }
                ,
                employeeType: {
                    value: '',
                    valid: false,
                    validators: [],
                }

            },
            formShow: false,
            posts: [],
            postsLoading: false,
            editPostBtn: false,
            editPostId: null,
        };
    }

    // Email validation using regex
    validateEmail = (value) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(value);
    };

    inputChangeHandler = (input, value) => {
        this.setState(prevState => {
            const updatedForm = {
                ...prevState.caseStudyForm,
                [input]: {
                    ...prevState.caseStudyForm[input],
                    value: value,
                    valid: true, // Assuming validation happens here
                }
            };
            
            return { caseStudyForm: updatedForm };
        });
    };
    
    toggleForm = () => {
        this.setNullAllInputField();
                    this.setState({ formData: [], editPostBtn: false, editPostId: null });
        this.setState((prevState) => ({
            formShow: !prevState.formShow,
        }));
    };

    componentDidMount() {
        this.fetchPosts();
    }

    setNullAllInputField = () => {
        this.setState((prevState) => ({
            caseStudyForm: {
                ...prevState.caseStudyForm,
                name: {
                    ...prevState.caseStudyForm.name,
                    value: '',
                },
                email: {
                    ...prevState.caseStudyForm.email,
                    value: '',
                },
                employeeSalesforceId: {
                    ...prevState.caseStudyForm.employeeSalesforceId,
                    value: '',
                }
                ,
                employeeType: {
                    ...prevState.caseStudyForm.employeeType,
                    value: '',

                }
            },
            editorState: EditorState.createEmpty(),
        }));
    };

    onCaseStudy = (event) => {
        event.preventDefault();
        const { name, email, employeeSalesforceId, employeeType } = this.state.caseStudyForm;
        if (!name.value.trim() || !email.value.trim()) {
            alert('Please enter both name and email.');
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email.value)) {
            alert('Please enter a valid email address.');
            return;
        }

        const requestData = {
            name: name.value,
            email: email.value,
            password: "123456",
            employeeSalesforceId: employeeSalesforceId.value,
            employeeType: employeeType.value,
            createdBy: localStorage.getItem('userId'),
            updatedBy: localStorage.getItem('userId'),
        };
        if (this.state.editPostBtn) {
            fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/employeManagement/` + this.state.editPostId, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            })
                .then(res => res.json())
                .then(data => {
                    this.toggleForm();
                    this.fetchPosts();
                    this.setNullAllInputField();
                    this.setState({ formData: [], editPostBtn: false, editPostId: null });
                })
                .catch(err => {
                    alert(err.message);
                    console.error('Error updating testimonial:', err);
                });
        } else {
            fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/employeManagement/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            })
                .then(res => res.json())
                .then(data => {
                    this.toggleForm();
                    this.fetchPosts();
                    this.setState({ selectedServices: [], formData: [] });
                    this.setNullAllInputField();
                })
                .catch(err => {
                    console.error('Error creating testimonial:', err);
                });
        }
    };

    fetchPosts = () => {
        this.setState({ postsLoading: true });
        fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/employeManagement/`)
            .then((res) => res.json())
            .then((resData) => {
         
                this.setState({ posts: resData, postsLoading: false });
            })
            .catch((err) => {
                console.error('Error fetching blog posts:', err);
                this.setState({ postsLoading: false });
            });
    };

    deletePostHandler = (postId) => {
        this.setState({ postsLoading: true });
        fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/employeManagement/${postId}`, {
            method: 'DELETE',
            headers: {
                Authorization: 'Bearer ' + this.props.token,
            },
        })
            .then((res) => {
                if (res.status !== 200 && res.status !== 201) {
                    throw new Error('Deleting a post failed!');
                }
                this.fetchPosts();
            })
            .catch((err) => {
                console.error('Error deleting post:', err);
                this.setState({ postsLoading: false });
            });
    };
    fetchSinglePost = (postId) => {
        this.setState({ postsLoading: true });
        fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/employeManagement/${postId}`, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + this.props.token,
            },
        })
            .then(res => {
                if (!res.ok) {
                    throw new Error('Failed to fetch the post data.');
                }
                return res.json(); // Parse the response to JSON
            })
            .then((resData) => {
                this.setState(prevState => ({
                    caseStudyForm: {
                        ...prevState.caseStudyForm,
                        name: {
                            ...prevState.caseStudyForm.name,
                            value: resData.name
                        },
                        email: {
                            ...prevState.caseStudyForm.email,
                            value: resData.email
                        },
                        employeeSalesforceId: {
                            ...prevState.caseStudyForm.employeeSalesforceId,
                            value: resData.employeeSalesforceId
                        },
                        employeeType: {
                            ...prevState.caseStudyForm.employeeType,
                            value: resData.employeeType
                        }
                    },
                    postsLoading: false,
                }));
            })
            .catch((err) => {
                console.error('Error fetching post:', err);
                this.setState({ postsLoading: false });
            });
    };

    editPost = (postId) => {
        this.setState({ editPostBtn: true, editPostId: postId }, () => {
            this.fetchSinglePost(postId);
            this.toggleForm();
        });
    };

    render() {
        return (
            <div className="main-div-login">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '1rem 0 ' }}>
                    <button onClick={this.toggleForm} className='buttonCreatePost'>
                        {this.state.formShow ? 'Close Employee Form' : 'Create New Employee'}
                    </button>
                </div>
                {this.state.formShow && (
                    <Auth>
                        <form onSubmit={this.onCaseStudy}>
                            <Input
                                id="name"
                                label="Employee Name"
                                type="text"
                                control="input"
                                onChange={this.inputChangeHandler}
                                value={this.state.caseStudyForm['name'].value}
                                valid={this.state.caseStudyForm['name'].valid}
                            />
                            <Input
                                id="email"
                                label="Employee Email"
                                type="email"
                                control="input"
                                onChange={this.inputChangeHandler}
                                value={this.state.caseStudyForm['email'].value}
                                valid={this.state.caseStudyForm['email'].valid}
                            />
                            <Input
                                id="employeeSalesforceId"
                                label="Employee SalesforceId"
                                type="text"
                                control="input"
                                onChange={this.inputChangeHandler}
                                value={this.state.caseStudyForm['employeeSalesforceId'].value}
                                valid={this.state.caseStudyForm['employeeSalesforceId'].valid}
                            />
                            <div style={{margin:"1rem 0"}}>
                            <select
                                value={this.state.caseStudyForm['employeeType'].value}
                                onChange={(event) => this.inputChangeHandler('employeeType', event.target.value)}
                                style={{width:'100%', padding:'0.3rem 0.5rem',border:"1px solid #ccc"}}
                            >
                                <option value="">Select Employee Type</option>
                                <option value="Employee">Employee</option>
                                <option value="Admin">Admin</option>
                            </select>
                            </div>

                            {this.state.editPostBtn ? (
                                <Button design="raised" type="submit" loading={this.props.loading}>
                                    Update Employee
                                </Button>
                            ) : (
                                <Button design="raised" type="submit" loading={this.props.loading}>
                                    Create Employee
                                </Button>
                            )}
                        </form>
                    </Auth>
                )}
                <Auth>
                    {this.state.postsLoading && <Loader />}
                    {this.state.posts.length <= 0 && !this.state.postsLoading ? (
                        <p style={{ textAlign: 'center' }}>No posts found.</p>
                    ) : null}
                    {this.state.posts.map((post) => (
                        <Post
                            key={post.id}
                            id={post.id}
                            title={post.name}
                            content={post.email}
                            onStartEdit={() => this.editPost(post.id)}
                            onDelete={() => this.deletePostHandler(post.id)}
                        />
                    ))}
                </Auth>
            </div>
        );
    }
}

export default EmployeeManagement;
