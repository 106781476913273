import React, { Component } from 'react'
import { Container, Row } from 'react-bootstrap';
import '../Components/css/contactus.css';
import { required, email } from '../adminPanelSRC/util/validators';

/*
*********************************************************************************************************
*  @Parent Component           :    App home page
*  @React Component Name       :    Contact
*  @Author                     :    <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*  @Company                    :    Antrazal
*  @Date                       :    05-07-2024
* 
*******************************************************************************************************
* Developer                                             TITLE
* <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)       contactUs section
********************************************************************************************************
*/

export default class Contact extends Component {

/*
*********************************************************************************************************
*   @React Component Name       :       Contact
*   @Method                     :       constructor
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       initialized the variabe 
*********************************************************************************************************
*/

    constructor(props) {
        super(props);
        this.state = {
            onsubmitContactUsForm: {
                name: {
                    value: '',
                    valid: false,
                    touched: false,
                    validators: []
                },
                email: {
                    value: '',
                    valid: false,
                    touched: false,
                    validators: [required, email]
                },
                content: {
                    value: '',
                    valid: false,
                    touched: false,
                    validators: []
                },
            },
            formIsValid: false
        };
    }

    /*
*********************************************************************************************************
*   @React Component Name       :       Contact
*   @Method                     :       setNullAllInputField
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       set all input value null 
*********************************************************************************************************
*/

    setNullAllInputField = () => {
        this.setState((prevState) => ({
            onsubmitContactUsForm: {
                ...prevState.onsubmitContactUsForm,
                name: {
                    ...prevState.onsubmitContactUsForm.name,
                    value: '',
                },
                email: {
                    ...prevState.onsubmitContactUsForm.email,
                    value: '',
                },
                content: {
                    ...prevState.onsubmitContactUsForm.content,
                    value: '',
                },
            }
        }));
    };

    /*
*********************************************************************************************************
*   @React Component Name       :       Contact
*   @Method                     :       inputBlurHandler
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       set the bluer th input fields 
*********************************************************************************************************
*/

    inputBlurHandler = input => {
        this.setState(prevState => {
            return {
                onsubmitContactUsForm: {
                    ...prevState.onsubmitContactUsForm,
                    [input]: {
                        ...prevState.onsubmitContactUsForm[input],
                        touched: true
                    }
                }
            };
        });
    };

    /*
*********************************************************************************************************
*   @React Component Name       :       Contact
*   @Method                     :       inputChangeHandler
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       this method update the input varable aferter changes on the inputs
*********************************************************************************************************
*/

    inputChangeHandler = (input, value) => {
        this.setState(prevState => {
            const updatedForm = {
                ...prevState.onsubmitContactUsForm,
                [input]: {
                    ...prevState.onsubmitContactUsForm[input],
                    value: value,
                    valid: prevState.onsubmitContactUsForm[input].validators.every(validator => validator(value)),
                    touched: true
                }
            };
            let formIsValid = true;
            for (const inputName in updatedForm) {
                formIsValid = formIsValid && updatedForm[inputName].valid;
            }
            return {
                onsubmitContactUsForm: updatedForm,
                formIsValid: formIsValid
            };
        });
    };

    /*
*********************************************************************************************************
*   @React Component Name       :       Contact
*   @Method                     :       
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       submit the contact us form and save the data in the DB
*********************************************************************************************************
*/

    onSubmitForm = (event) => {
        event.preventDefault();
        const { name, email, content } = this.state.onsubmitContactUsForm;
        const requestData = {
            name: name.value,
            email: email.value,
            content: content.value,
            createdBy: name.value,
            updatedBy: name.value,
        };
        fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/contactus`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        })
            .then((res) => res.json())
            .then((data) => {
                this.setNullAllInputField()
            })
            .catch((err) => {
                console.error('Error submitting contact form:', err);
            });
    };

    render() {
        return (
            <section className="contact_Bg" _content-contact id='contact' style={{ scrollBehavior: "smooth" }}>
                <Container className="">
                    <Row>
                        <div className="col-md-10 offset-md-1">
                            <div className="contact_inner">
                                <Row>
                                    <div className="col-md-10">
                                        <div className="contact_form_inner">
                                            <div className="contact_field">
                                                <h3>Contact Us</h3>
                                                <p>Feel Free to contact us any time. We will get back to you as soon as we can!.</p>
                                                <form action="" onSubmit={this.onSubmitForm}>
                                                    <input
                                                        type="text"
                                                        className="form-control form-group"
                                                        placeholder="Name"
                                                        value={this.state.onsubmitContactUsForm.name.value}
                                                        onChange={(e) => this.inputChangeHandler('name', e.target.value)}
                                                    />
                                                    <input
                                                        className={this.state.onsubmitContactUsForm['email'].valid ? 'valid form-control form-group' : 'invalid form-control form-group'}
                                                        id='email'
                                                        type="text"
                                                        placeholder="Email Address"
                                                        value={this.state.onsubmitContactUsForm.email.value}
                                                        onChange={(e) => this.inputChangeHandler('email', e.target.value)}
                                                        onBlur={() => this.inputBlurHandler('email')}
                                                    />
                                                    <textarea
                                                        className="form-control form-group"
                                                        placeholder="Message"
                                                        id='content'
                                                        value={this.state.onsubmitContactUsForm.content.value}
                                                        onChange={(e) => this.inputChangeHandler('content', e.target.value)}
                                                    ></textarea>
                                                    <button className="btn_contact contact_form_submit" style={{ textDecoration: " none", color: "aliceblue" }} type='submit' disabled={!this.state.formIsValid}>
                                                        CONTACT US
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="right_conatct_social_icon d-flex align-items-end">
                                            <div className="socil_item_inner d-flex">
                                                <li><a href="#"><i className="fab fa-facebook-square"></i></a></li>
                                                <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                                                <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                            </div>
                                        </div>
                                    </div>
                                </Row>
                                <div className="contact_info_sec">
                                    <h4>Contact Info</h4>
                                    <div className="d-flex info_single align-items-center " >
                                        <i className="fa-solid fa-envelope"></i>
                                        <a href="mailto:info@antrazal.com" style={{ textDecoration: "none" }} className='email_link'>
                                            <span>info@antrazal.com</span></a>
                                    </div>
                                    <div className="d-flex info_single align-items-center" >
                                        <i className="fas fa-envelope-open-text"></i>
                                        <span>www.antrazal.com</span>
                                    </div>
                                    <div className="d-flex info_single align-items-center" >
                                        <i className="fas fa-map-marked-alt"></i>
                                        <span>105, Sangam Tower, Church Road, Jaipur, 302001, Rajasthan, India</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </section>
        )
    }
}
