import React from 'react';
import Loader from '../../components/Loader/Loader';
import './filepickerforarray.css'
/*
*********************************************************************************************************
*  @Parent Component           :    multiple file picker 
*  @React Component Name       :    FilePickerFORArray
*  @Author                     :    <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*  @Company                    :    Antrazal
*  @Date                       :    04-07-2024
* 
*******************************************************************************************************
* Developer                                             TITLE
* <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)       Progressive add products LWC component development
********************************************************************************************************
*/
const FilePickerFORArray = ({ id, label, onChange, onBlur, value, valid, touched, urlLoading }) => {

  return (
    <div className='input'>
      <label htmlFor={id}>{label}</label>
      <input
        type="file"
        id={id}
        onChange={(e) => onChange(e.target.value, e.target.files)}
        onBlur={onBlur}
      />
      {touched && !valid && <p style={{ color: 'red', margin: "0" }}>Please choose a valid file.</p>}
      {urlLoading === 0 || value ? (
        <p style={{ margin: "0" }}> <img src={value} style={{ height: "2rem" }} alt='' /></p>
      ) : (<Loader />)}
    </div>
  );
};

export default FilePickerFORArray;
