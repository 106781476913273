import React, { useState } from "react";
import Service from "./Components/Service";
import Banner from "./Components/Banner";
import Work from "./Components/Work";
import Testimonial from "./Components/Testimonial";
import Contact from "./pages/Contact"
import CarouselLogo from "./Components/CarouselLogo";
/*
*********************************************************************************************************
*  @Parent Component           :    main page 
*  @React Component Name       :    MainPage
*  @Author                     :    <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*  @Company                    :    Antrazal
*  @Date                       :    05-07-2024
* 
*******************************************************************************************************
* Developer                                             TITLE
* <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)       Home page
********************************************************************************************************
*/
export default function MainPage(props) {
	const [navigationUrl,setNavigatonUrl] = useState(window.location.pathname);

	return (
		<div style={{ scrollBehavior: "smooth" }}>
			{(localStorage.getItem('userId') != null && navigationUrl.startsWith("/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/antrazal/admin") )|| navigationUrl.startsWith("/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/adminPanelHrm")  ? (
				<>
				</>
			) : (<>
			<div style={{height:'100vh'}}>
			<Banner />
			<CarouselLogo />
			</div>
				<Service />
				<Work />
				<Testimonial />
				<Contact />
			</>
			)}
		</div>
	)
}
