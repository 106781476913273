import React from 'react';
import './css/loader.css';
/*
*********************************************************************************************************
*  @Parent Component           :    AdminPageOfHRM,TimeCard
*  @React Component Name       :    LoaderForHrm
*  @Author                     :    <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*  @Company                    :    Antrazal
*  @Date                       :    04-07-2024
* 
*******************************************************************************************************
* Developer                                             TITLE
* <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)       Loader component
********************************************************************************************************
*/
export default function LoaderForHrm(props) {
    return (
        <div className="loader-container" style={{ display: props.loading ? 'block' : 'none',zIndex:'9999' }}>
      <div className="loader"></div>
    </div>
    );
}
