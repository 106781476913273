import React, { Component } from 'react'
import Auth from '../Auth/Auth';
import './post.css'
/*
*********************************************************************************************************
*  @Parent Component           :    App component 
*  @React Component Name       :    
*  @Author                     :    <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*  @Company                    :    Antrazal
*  @Date                       :    04-07-2024
* 
*******************************************************************************************************
* Developer                                             TITLE
* <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)       ContactUs admin page 
********************************************************************************************************
*/
export default class ContactUs extends Component {
    /*
    *********************************************************************************************************
    *   @React Component Name       :       ContactUs
    *   @Method                     :       constructor
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       initialized input variables
    *********************************************************************************************************
    */
    constructor(props) {
        super(props);
        this.state = {
            posts: [],
            postsLoading: false,
        };
    }
    /*
    *********************************************************************************************************
    *   @React Component Name       :       ContactUs
    *   @Method                     :       componentDidMount
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       this get all services or blog data initialy or any updation
    *********************************************************************************************************
    */
    componentDidMount() {
        this.fetchPosts();
    }
    /*
    *********************************************************************************************************
    *   @React Component Name       :       ContactUs
    *   @Method                     :       fetchPosts
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       this method call api for fetch blog data from the server
    *********************************************************************************************************
    */
    fetchPosts = () => {
        this.setState({ postsLoading: true });
        fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/contactus`)
            .then((res) => res.json())
            .then((resData) => {
                this.setState({ posts: resData, postsLoading: false });
            })
            .catch((err) => {
                console.error('Error fetching blog posts:', err);
                this.setState({ postsLoading: false });
            });
    };

    render() {
        return (
            <Auth>
                {this.state.posts.length <= 0 && !this.state.postsLoading ? (
                    <p style={{ textAlign: 'center' }}>No posts found.</p>
                ) : null}
                <table style={{ width: "100%", borderCollapse: "" }}>
                    <tr style={{ borderBottom: "1px solid #ccc" }}>
                        <th style={{ textAlign: "center" }}>Name</th>
                        <th style={{ textAlign: "center" }}>Email</th>
                        <th style={{ textAlign: "center" }}>Message</th>
                    </tr>
                    {this.state.posts.map((post) => (
                        <tr style={{ borderBottom: "1px solid #ccc" }}>
                            <td style={{ padding: "0.5rem 0.5rem", border: "1px solid #ccc", textAlign: "left", }}>{post.name}</td>
                            <td style={{ padding: "0.5rem 0.5rem", border: "1px solid #ccc", textAlign: "left", }}>{post.email}</td>
                            <td style={{ padding: "0.5rem 0.5rem", border: "1px solid #ccc", textAlign: "left", }}>{post.content}</td>
                        </tr>
                    ))}
                </table>
            </Auth>
        )
    }
}
