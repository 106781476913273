import React, { useState } from 'react'
import TextEditor from './TextEditor';
import './css/tooltipStyle.css'
import './css/kanbanDetailViewCss.css'
import SmallLoader from './SmallLoader';
import LoaderForHrm from './LoaderForHrm';
import Select from 'react-select';
import TimeLogOnTaskDetailPage from './TimeLogOnTaskDetailPage';
import NewTimeCardForTaskDetailView from './NewTimeCardForTaskDetailView';
export default function TaskDetailView({ selectedId, projectData, allUserOption, handelClosedBtnKanbanDetails, updateProjectData, handleIdClick, SetLoader, setMessageWithTimeout, loading, newProjectHistory, projectComments, relativeTask, loadingSectionRelatedItem, loadingSectionComment, loadingSectionHistory, activeTab, SetActiveTabCommentOrHistory, firstConditonOfTaskChild }) {
    // const [activeTab, setActiveTab] = useState('comments');
    const [smallLoaderId, setSmallLoaderId] = useState(false);
    const [editedStatus, setEditedStatus] = useState({});
    const [WindowForActualHour, setWindowForActualHour] = useState(false)
    const [loaderForNewComment, setLoaderForNewComment] = useState({ loading: true, Id: null })
    const [actualHours, setActualHours] = useState();
    const [idStateClosed, setStateClosed] = useState({ id: null, index: null })
    const [sliderToggle, setSliderToggle] = useState(false)
    const [openTimeCardWindow, setOpenTimeCardWindow] = useState(false)
    const [closeTaskOldNewValue, setCloseTaskOldNewValue] = useState({oldValue:"",newValue:""})
    const getTypeColor = (item) => {
        switch (item) {
            case 'Bug':
                return 'red';
            case 'User Story':
                return 'green';
            case 'Task':
                return 'blue';
            default:
                return 'violet';
        }
    }
    const formatDateTime = (dateTimeString) => {
        if (!dateTimeString) {
            return "";
        }
        const date = new Date(dateTimeString);
        const day = date.getDate();
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();
        const formattedDate = `${month} ${day} ${year}`;
        return formattedDate;
    }


    const openNewTabWindow = (id) => {

        const url = `/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/adminPanelHrm/task-detail/?id=${id}`;
        window.open(url, '_blank');
    }

    const setCommentLoadeBegin = (data) => {
        setLoaderForNewComment(data)
    }

    function newFormatDateTime(dateTimeString) {
        const dateTime = new Date(dateTimeString);
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = months[dateTime.getMonth()];
        const day = dateTime.getDate().toString().padStart(2, '0');
        const year = dateTime.getFullYear();
        const weekday = dateTime.toLocaleString('en-GB', { weekday: 'short' });
        const formattedDate = `${month} ${day} ${year}`;
        let hours = dateTime.getHours();
        const minutes = dateTime.getMinutes().toString().padStart(2, '0');
        const period = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        const formattedTime = `${hours}:${minutes} ${period}`;
        return `${formattedDate}, ${formattedTime}`;
    }
    const removeUnderScore = (str) => {

        let result = str.replace(/__c/g, " ");
        result = result.replace(/_/g, " ");
        return result;


    }
    function newFormatDateTime(dateTimeString) {
        const dateTime = new Date(dateTimeString);
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = months[dateTime.getMonth()];
        const day = dateTime.getDate().toString().padStart(2, '0');
        const year = dateTime.getFullYear();
        const formattedDate = `${day} ${month} ${year}`;
        let hours = dateTime.getHours();
        const minutes = dateTime.getMinutes().toString().padStart(2, '0');
        const period = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        const formattedTime = `${hours}:${minutes} ${period}`;
        return `${formattedDate}, ${formattedTime}`;
    }


    const statusOptions = [
        { value: "Defined", label: "Defined" },
        { value: "Ready", label: "Ready" },
        { value: "In Progress", label: "In Progress" },
        { value: "Draft", label: "Draft" },
        { value: "Review", label: "Review" },
        { value: "Blocked", label: "Blocked" },
        { value: "Demoed", label: "Demoed" },
        { value: "Prod", label: "Prod" },
        { value: "Closed", label: "Closed" }
    ];



    const handleStatusHistoryUpdate = async (id, selectedOption, oldOption) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/createHistory`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        Status__c: selectedOption.value,
                        Actual_Hours__c: null,
                        fieldApiName: "Status__c",
                        entityId: id,
                        newValue: selectedOption.value,
                        oldValue: oldOption,
                        conId: localStorage.getItem('contactUserId'),
                        objectApiName: "ANT_Project_Entity__c",
                    }),
                }
            );

            if (!response.ok) {
                throw new Error('Failed to update project history status');
            }
         
        } catch (error) {
            setMessageWithTimeout('Status history not updated', 'error');
            console.error('Error:', error);
        } finally {
            setSmallLoaderId(false);
        }
    };

    const handleStatusChange = async (id, selectedOption, index, oldOption) => {

        setStateClosed({ id, index });
        setSmallLoaderId(true);
        setEditedStatus((prevState) => ({
            ...prevState,
            [id]: selectedOption.value,
        }));

        if (selectedOption.value.toLowerCase() === 'closed') {
            setWindowForActualHour(true);
            setCloseTaskOldNewValue({oldValue:oldOption,newValue:selectedOption.value})
            return;
        }

        try {
            const response = await fetch(
                `${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/updateProjectEntityById/${id}`,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        Status__c: selectedOption.value,
                        Actual_Hours__c: null,
                    }),
                }
            );

            if (!response.ok) {
                throw new Error('Failed to update project status');
            }

            await handleStatusHistoryUpdate(id, selectedOption, oldOption);
          
            projectData[0].Status__c = selectedOption.value;
            projectData[0].Actual_Hours__c = 0;
        } catch (error) {
            setMessageWithTimeout('Status not updated', 'error');
            console.error('Error:', error);
        } finally {
            setSmallLoaderId(false);
        }
    };



    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: state.selectProps.value && state.selectProps.value.value === 'In Progress' ? '#d9f4ff' :
                state.selectProps.value && state.selectProps.value.value === 'Blocked' ? '#ffcaca' :
                    state.selectProps.value && state.selectProps.value.value === 'Review' ? '#fef0dd' :
                        state.selectProps.value && state.selectProps.value.value === 'Closed' ? '#d5ffee' :
                            state.selectProps.value && state.selectProps.value.value === 'Defined' ? '#f2f2f2' :
                                'white',
        }),
    };
    const handlWindowForActualHour = () => {
        setWindowForActualHour(!WindowForActualHour)
        setSmallLoaderId(false);
    }
    const handleSave = async (e) => {
        e.preventDefault();
        setSmallLoaderId(true);
        try {
            setWindowForActualHour(false);
            const response = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/updateProjectEntityById/${selectedId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    Status__c: 'Closed',
                    Actual_Hours__c: Number(actualHours),
                }),
            });

            if (!response.ok) {
                setSmallLoaderId(null);
                setMessageWithTimeout('Failed to update project status', 'error');
                setCloseTaskOldNewValue({oldValue:'',newValue:''})
                throw new Error('Failed to update project status');
            }
            setSmallLoaderId(null);
            await handleStatusHistoryUpdate(idStateClosed.id, closeTaskOldNewValue.newValue, closeTaskOldNewValue.oldValue);
           
            projectData[0].Status__c = 'Closed';
            projectData[0].Actual_Hours__c = Number(actualHours);
            setActualHours('');
            setCloseTaskOldNewValue({oldValue:'',newValue:''})
        } catch (error) {
            setSmallLoaderId(null);
            setMessageWithTimeout('Status not updated', 'error');
            console.error('Error:', error);
        }
    };
    const handleTimeEstimatedHoursChange = (value) => {
        const floatValue = parseFloat(value);
        setActualHours(floatValue);
    };
    const mobileViewSliderHandeler = () => {
        setSliderToggle(!sliderToggle)
    }

    const handelOpenTimeCardWindow = () => {
      
        if (projectData[0].Assigned_To__r.Id == localStorage.getItem('contactUserId')) {
            setOpenTimeCardWindow(!openTimeCardWindow)
        }
        else {
            setMessageWithTimeout('You are not allowed to fill the timeCard for this project Task', 'error');
        }
      
    }


    return (
        <div className='backgroundColor'>
            {WindowForActualHour && (
                <form className="centered-window" onSubmit={handleSave}>
                    <div className='actualhourWindowCloseDiv' onClick={handlWindowForActualHour}>
                        <svg viewBox="0 0 24 24" fill="none" ><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="Menu / Close_MD"> <path id="Vector" d="M18 18L12 12M12 12L6 6M12 12L18 6M12 12L6 18" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g> </g></svg>
                    </div>
                    <div className="formForWindoDiv">
                        <label htmlFor="">Enter Actual Hours</label>
                        <input
                            type="number"
                            min="0"
                            placeholder="Enter actual hours"
                            className="timeCardTimeinput"
                            value={actualHours}
                            onChange={(e) => handleTimeEstimatedHoursChange(e.target.value)}
                            required
                            step="any"
                            id="hrmmytimeInput"
                        />
                        <button type="submit">Save</button>
                    </div>
                </form>
            )}
            {
                openTimeCardWindow ? <NewTimeCardForTaskDetailView projectData={projectData} handelOpenTimeCardWindow={handelOpenTimeCardWindow} /> : null
            }

            <div className='kanbanCardDetail'>
                <div className='adminPanelMrm-kanban-card-detail-div' style={{ width: "100%", border: "1px solid #ccc", padding: '0', color: 'black', backgroundColor: 'rgb(250 250 250)' }}>
                    <div style={{ position: 'sticky', top: 0, zIndex: 999 }}>

                        {loading ? (<></>) :
                            (
                                <ul className="tags " style={{}}>
                                    <div className='innerTags'>

                                        <div className='assign-to-Div svgForTag'>
                                            <div className='assignToLogoStyleNew'>
                                                <svg viewBox="0 0 24 24" fill="none">
                                                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                                    <g id="SVGRepo_iconCarrier">
                                                        <path d="M12 2 A10 10 0 0 1 19.09 4.91"
                                                            stroke={getTypeColor(projectData[0]?.RecordType?.Name || '')}
                                                            stroke-width="2"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"></path>
                                                        <path d="M21.66 12 A10 10 0 0 1 19.09 19.09"
                                                            stroke={getTypeColor(projectData[0]?.RecordType?.Name || '')}
                                                            stroke-width="2"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"></path>
                                                        <path d="M12 21.66 A10 10 0 0 1 4.91 19.09"
                                                            stroke={getTypeColor(projectData[0]?.RecordType?.Name || '')}
                                                            stroke-width="2"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"></path>
                                                        <path d="M2.34 12 A10 10 0 0 1 4.91 4.91"
                                                            stroke={getTypeColor(projectData[0]?.RecordType?.Name || '')}
                                                            stroke-width="2"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"></path>
                                                        <circle cx="12" cy="12" r="6"
                                                            fill={getTypeColor(projectData[0]?.RecordType?.Name || '')}></circle>
                                                    </g>
                                                </svg>
                                                <div className='tooltipNewLeft'> {projectData[0]?.RecordType?.Name || ''}</div>
                                            </div>
                                        </div>
                                        <li className="tag">
                                            {projectData[0]?.Name || ''}
                                        </li>
                                    </div>
                                    <div className='innerTags'>
                                        <li className='assign-to-Div svgForTag'>
                                            <button className='approvedBtn' onClick={handelOpenTimeCardWindow}>Time log</button>
                                        </li>
                                        <li className='assign-to-Div svgForTag'>
                                            <div className='assignToLogoStyleNew' onClick={() => openNewTabWindow(projectData[0].Id)}>
                                                <svg
                                                    fill="rgb(16, 17, 162)"
                                                    viewBox="0 0 64 64"
                                                    version="1.1"
                                                    style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}
                                                >
                                                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                                    <g id="SVGRepo_iconCarrier">
                                                        <rect id="Icons" x="-896" y="0" width="1280" height="800" style={{ fill: 'none' }}></rect>
                                                        <g id="Icons1" serifId="Icons"><g id="Strike"></g><g id="H1"></g><g id="H2"></g><g id="H3"></g><g id="list-ul"></g><g id="hamburger-1"></g><g id="hamburger-2"></g><g id="list-ol"></g><g id="list-task"></g><g id="trash"></g><g id="vertical-menu"></g><g id="horizontal-menu"></g><g id="sidebar-2"></g><g id="Pen"></g><g id="Pen1" serifId="Pen"></g><g id="clock"></g><g id="external-link">
                                                            <path d="M36.026,20.058l-21.092,0c-1.65,0 -2.989,1.339 -2.989,2.989l0,25.964c0,1.65 1.339,2.989 2.989,2.989l26.024,0c1.65,0 2.989,-1.339 2.989,-2.989l0,-20.953l3.999,0l0,21.948c0,3.308 -2.686,5.994 -5.995,5.995l-28.01,0c-3.309,0 -5.995,-2.687 -5.995,-5.995l0,-27.954c0,-3.309 2.686,-5.995 5.995,-5.995l22.085,0l0,4.001Z"></path>
                                                            <path d="M55.925,25.32l-4.005,0l0,-10.481l-27.894,27.893l-2.832,-2.832l27.895,-27.895l-10.484,0l0,-4.005l17.318,0l0.002,0.001l0,17.319Z"></path>
                                                        </g><g id="hr"></g><g id="info"></g><g id="warning"></g><g id="plus-circle"></g><g id="minus-circle"></g><g id="vue"></g><g id="cog"></g><g id="logo"></g><g id="radio-check"></g><g id="eye-slash"></g><g id="eye"></g><g id="toggle-off"></g><g id="shredder"></g><g id="spinner--loading--dots-" serifId="spinner [loading, dots]"></g><g id="react"></g><g id="check-selected"></g><g id="turn-off"></g><g id="code-block"></g><g id="user"></g><g id="coffee-bean"></g><g id="coffee-beans">
                                                                <g id="coffee-bean1" serifId="coffee-bean"></g>
                                                            </g><g id="coffee-bean-filled"></g><g id="coffee-beans-filled">
                                                                <g id="coffee-bean2" serifId="coffee-bean"></g>
                                                            </g><g id="clipboard"></g><g id="clipboard-paste"></g><g id="clipboard-copy"></g><g id="Layer1"></g>
                                                        </g>
                                                    </g>
                                                </svg>
                                                <div className='tooltipNewLeftNewRight'>New Tab</div>
                                            </div>
                                        </li>
                                        <li className='assign-to-Div svgForTag' onClick={() => handelClosedBtnKanbanDetails('')}>
                                            <div className='assignToLogoStyleNew' style={{ borderRadius: '0' }} >
                                                <svg viewBox="0 0 24 24" fill="none" ><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" ></g><g id="SVGRepo_iconCarrier"> <g id="Menu / Close_MD"> <path id="Vector" d="M18 18L12 12M12 12L6 6M12 12L18 6M12 12L6 18" stroke="#6c6c6c" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g> </g></svg>

                                            </div>
                                        </li>
                                    </div>

                                </ul>)}
                        <div className='rightSliderOpenDiv'>
                            <svg
                                fill="rgb(16, 17, 162)"
                                version="1.1"
                                id="Capa_1"
                                viewBox="0 0 220.682 220.682"
                                xmlSpace="preserve"
                                style={{ width: "1rem", height: "1rem", transform: sliderToggle ? "rotate(180deg)" : 'rotate(0deg)' }}
                                onClick={mobileViewSliderHandeler}
                            >
                                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <g>
                                        <polygon points="92.695,38.924 164.113,110.341 92.695,181.758 120.979,210.043 220.682,110.341 120.979,10.639"></polygon>
                                        <polygon points="28.284,210.043 127.986,110.341 28.284,10.639 0,38.924 71.417,110.341 0,181.758"></polygon>
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div style={{ display: "flex", width: "100%", height: "88%", overflow: "hidden", position: 'relative' }}>

                        <div style={{}} className={`scrollClassLeft rightDetailMainDivSection ${sliderToggle ? 'displayWidth' : ''}`}>

                            {loading ? (<LoaderForHrm loading={loading} />) : (
                                <div>

                                    <h3 className='colorClassHeading hrmHeadingStyle'> {projectData[0]?.Title__c
                                        || ""} </h3>
                                </div>
                            )}

                            <h1 className='taskDetailDiscription colorClassHeading HrmsubHeading'>Description:</h1>

                            <div style={{ marginTop: "1rem" }}>
                                {loading ? (<></>) : (

                                    <div className='discriptionOfTaskDiv'>
                                        <div dangerouslySetInnerHTML={{
                                            __html: projectData[0]?.Description__c || ""
                                        }} style={{ fontSize: "1rem", overflowWrap: "anywhere" }} className="discriptionhtml" />

                                    </div>
                                )}
                                {
                                    (relativeTask[0]?.Parent_Entity__r || relativeTask[0]?.ProjectEntities__r?.records) ? (
                                        <h1 className='taskDetailRealatedHeading colorClassHeading HrmsubHeading' >Related Task</h1>
                                    ) : null
                                }
                                {loadingSectionRelatedItem ? (
                                    <LoaderForHrm loading={loadingSectionRelatedItem} />
                                ) : (
                                    relativeTask[0]?.Parent_Entity__r || relativeTask[0]?.ProjectEntities__r?.records ? (
                                        < >

                                            <div className='EntityTreeDiv' style={{ color: 'black' }}>
                                                {

                                                    relativeTask[0]?.Parent_Entity__r ? (
                                                        <div className='chilEntityDiv' onClick={() => handleIdClick(relativeTask[0]?.Parent_Entity__r.Id || null)}>
                                                            <div className='innerchildTaskDetails'>
                                                                <div className='leftInnerchildTaskDetails'>
                                                                    <p className='taskDetailTaskId'>
                                                                        {relativeTask[0]?.Parent_Entity__r.Name ?? ""}
                                                                    </p>

                                                                    <p className='taskDetailTitle'>{relativeTask[0]?.Parent_Entity__r.Title__c ?? ""}</p>
                                                                </div>
                                                                <div>
                                                                    <p className='taskrecordType'>
                                                                        {relativeTask[0]?.Parent_Entity__r?.Status__c || ""}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className='assign-to-Div'>
                                                                <div className='assignToLogoStyle'>
                                                                    {relativeTask[0]?.Parent_Entity__r.Assigned_To__r.Name ? relativeTask[0]?.Parent_Entity__r.Assigned_To__r.Name[0] : ""}
                                                                    <div className='tooltip2'>{relativeTask[0]?.Parent_Entity__r.Assigned_To__r.Name ? relativeTask[0]?.Parent_Entity__r.Assigned_To__r.Name : ""}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : null
                                                }
                                                {

                                                    (relativeTask[0]?.ProjectEntities__r?.records || []).map((item, index) => (
                                                        <div key={index} className='chilEntityDiv' onClick={() => handleIdClick(item.Id)}>
                                                            <div className='innerchildTaskDetails'>
                                                                <div className='leftInnerchildTaskDetails'>
                                                                    <p className='taskDetailTaskId'>
                                                                        {item.Name && item.Name}
                                                                    </p>
                                                                    <p className='taskDetailTitle'>
                                                                        {item.Title__c && item.Title__c}
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <p className='taskrecordType'>
                                                                        {item.Status__c ?? ""}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className='assign-to-Div'>
                                                                <div className='assignToLogoStyle'>
                                                                    {item.Assigned_To__r.Name ? item.Assigned_To__r.Name[0] : ""}
                                                                    <div className='tooltip2'>Assignee: {item.Assigned_To__r.Name ? item.Assigned_To__r.Name : ""}</div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    ))
                                                }
                                            </div>
                                        </>
                                    ) : null

                                )}
                                <div>
                                    <div style={{ display: 'flex', cursor: 'pointer', marginTop: '0.6rem' }}>

                                        {
                                            firstConditonOfTaskChild ? (<></>) : (<>
                                                <h6 onClick={() => SetActiveTabCommentOrHistory('comments')}
                                                    style={{
                                                        margin: '0rem 0.5rem 0.7rem 0',

                                                        borderBottom: activeTab === 'comments' ? '2px solid #1011a2' : 'none',
                                                        paddingBottom: '0.5rem',
                                                        marginRight: '1rem',
                                                        fontWeight: 'bold',
                                                    }} className='colorClassHeading HrmsubHeading'>Comments</h6>
                                            </>)

                                        }
                                        <h6 onClick={() => SetActiveTabCommentOrHistory('history')}
                                            style={{
                                                margin: '0rem 0px 0.7rem 0',

                                                fontWeight: 'bold',
                                                borderBottom: activeTab === 'history' ? '2px solid #1011a2' : 'none',
                                                paddingBottom: '0.5rem'
                                            }} className='colorClassHeading HrmsubHeading'>History</h6>
                                    </div>

                                    {activeTab === 'comments' && (
                                        <div>
                                            <div>{
                                                firstConditonOfTaskChild ? (<></>) : (<>
                                                    <TextEditor allUserOption={allUserOption} taskId={selectedId} updateProjectData={updateProjectData} SetLoader={SetLoader} setCommentLoadeBegin={setCommentLoadeBegin} setMessageWithTimeout={setMessageWithTimeout} />
                                                </>)

                                            }
                                            </div>
                                            {loadingSectionComment ? (
                                                <LoaderForHrm loading={loadingSectionComment} />
                                            ) : (
                                                firstConditonOfTaskChild ? (
                                                    <></>
                                                ) : (
                                                    <>
                                                        {projectComments && projectComments[0]?.Project_Entity_Commnets__r?.records && projectComments[0].Project_Entity_Commnets__r.records.map(commentItem => (
                                                            <div className="comment-card" key={commentItem.Id}>
                                                                <div className='adminPanelMrm-discription-user-date-div'>
                                                                    <div>
                                                                        {loaderForNewComment.loading && commentItem.Id === loaderForNewComment.Id ? (
                                                                            <SmallLoader />
                                                                        ) : (
                                                                            <div className='circlLogoStyle'>
                                                                                {commentItem.Commented_By__r ? commentItem.Commented_By__r.Name[0] : ""}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <div style={{ marginLeft: "1rem", color: 'black' }}>
                                                                        <p style={{ fontWeight: "500", fontSize: "0.8rem", color: "", margin: '0', marginBottom: "0.3rem" }}>
                                                                            {commentItem.Commented_By__r ? commentItem.Commented_By__r.Name : ""}
                                                                        </p>
                                                                        <p style={{ fontSize: "0.8rem", margin: 0 }}>
                                                                            {newFormatDateTime(commentItem.CreatedDate ? commentItem.CreatedDate : '')}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div dangerouslySetInnerHTML={{ __html: commentItem?.Comment__c || '' }}></div>
                                                            </div>
                                                        ))}
                                                    </>
                                                )
                                            )}
                                        </div>
                                    )}
                                    {activeTab === 'history' && (
                                        <div>
                                            <ol className="activity-stream">
                                                {loadingSectionHistory ? (
                                                    <LoaderForHrm loading={loadingSectionHistory} />
                                                ) : (
                                                    newProjectHistory && newProjectHistory.map(item => (
                                                        <li>
                                                            <div className=" icon"><div className='historyAssignToLogoStyle'>B
                                                                <div className='historyTooltip2'>Assignee: Bajrang Mahawar</div>
                                                            </div></div>
                                                            <div className='historyCardDiv'>
                                                                <p className='heding-historyCard'>{removeUnderScore(item ? item.Field : '')}</p>
                                                                <div className='history-ChangesHeading'>
                                                                    <p>{item ? item.OldValue : ''}</p>
                                                                    {
                                                                        item.NewValue != null || item.OldValue != null ? (<p>
                                                                            <svg fill="#000000" version="1.1" id="Layer_1" viewBox="0 0 330 330" xmlSpace="preserve" transform="rotate(90)">
                                                                                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                                                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                                                                <g id="SVGRepo_iconCarrier">
                                                                                    <path id="XMLID_29_" d="M100.606,100.606L150,51.212V315c0,8.284,6.716,15,15,15c8.284,0,15-6.716,15-15V51.212l49.394,49.394 
            C232.322,103.535,236.161,105,240,105c3.839,0,7.678-1.465,10.606-4.394c5.858-5.857,5.858-15.355,0-21.213l-75-75 
            c-5.857-5.858-15.355-5.858-21.213,0l-75,75c-5.858,5.857-5.858,15.355,0,21.213C85.251,106.463,94.749,106.463,100.606,100.606z"></path>
                                                                                </g>
                                                                            </svg>
                                                                        </p>) : null
                                                                    }
                                                                    <p >{item ? item.NewValue : ''}</p>
                                                                </div>
                                                                <p className='historyTimeDateP'>{newFormatDateTime(item ? item.CreatedDate : '')}</p>
                                                            </div>
                                                        </li>
                                                    )))
                                                }
                                            </ol>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={`scrollClassLeft leftDetailMainDivSection ${sliderToggle ? 'widthScreen' : ''}`} style={{}}>
                            {loading ? (<LoaderForHrm loading={loading} />) : (
                                <div className='' >
                                    <div className='innerGridTaskDetail1'>

                                        <div style={{ marginBottom: "0.5rem" }}>  {
                                            smallLoaderId ? (
                                                <SmallLoader />
                                            ) : (
                                                <Select
                                                    value={statusOptions.find(option => option.value === (editedStatus[selectedId] || projectData[0]?.Status__c || ''
                                                    ))}
                                                    onChange={(selectedOption) => { handleStatusChange(projectData[0].Id, selectedOption, projectData[0].Id, projectData[0]?.Status__c) }}
                                                    options={statusOptions}
                                                    defaultValue={projectData[0]?.Status__c || ''}
                                                    styles={customStyles}
                                                    style={{ color: "black !important" }}
                                                />

                                            )}</div>
                                        <div style={{}} className='centerdueDateDiv1'>
                                            <h6 style={{ fontSize: "0.8rem", }}>Project:</h6>
                                            <h4 style={{ fontSize: "0.8rem" }}> {projectData[0]?.Project__r.Title__c
                                                || null} </h4>
                                        </div>
                                        <div className='centerdueDateDiv1'>
                                            <h6 style={{ fontSize: "0.8rem" }}>Reporter:</h6>
                                            <div style={{ display: "flex", gap: "0.3rem" }}>
                                                <div className='assignToLogoStyleNew'>
                                                    <div className='nameShowDiv'> {projectData[0]?.Reporter__r && projectData[0].Reporter__r.Name
                                                        ? projectData[0].Reporter__r.Name[0]
                                                        : 'N'}</div>
                                                    <div className='tooltipNewLeft'>{projectData[0]?.Reporter__r && projectData[0].Reporter__r.Name
                                                        ? projectData[0].Reporter__r.Name
                                                        : 'Null'}</div>
                                                </div>
                                                <h4 style={{ color: "", fontSize: '0.8rem' }}>{projectData[0]?.Reporter__r && projectData[0].Reporter__r.Name
                                                    ? projectData[0].Reporter__r.Name
                                                    : 'Null'}</h4>
                                            </div>
                                        </div>
                                        <div className='centerdueDateDiv1'>
                                            <h6 style={{ fontSize: "0.8rem", }}>Assignee:</h6>
                                            <div style={{ display: "flex", gap: "0.3rem" }}>
                                                <div className='assignToLogoStyleNew'>
                                                    <div className='nameShowDiv'>{projectData[0]?.Assigned_To__r.Name[0]
                                                        || null}</div>
                                                    <div className='tooltipNewLeft'> {projectData[0]?.Assigned_To__r.Name || ''}</div>
                                                </div>
                                                <h4 style={{ fontSize: "0.8rem" }}>{projectData[0]?.Assigned_To__r.Name || ''}</h4>
                                            </div>

                                        </div>
                                        {/* </div >
                                        <div className='innerGridTaskDetail'> */}

                                        <div style={{}} className='centerdueDateDiv1'>
                                            <h6 style={{ fontSize: "0.8rem", }}>Estimates:</h6>
                                            <h4 style={{ fontSize: "0.9rem" }}>{projectData[0]?.Estimated_Hours__c || 0}</h4>
                                        </div>

                                        <div className='centerdueDateDiv1'>
                                            <h6 style={{ fontSize: "0.8rem", }}>Actual:</h6>
                                            <h4 style={{ fontSize: "0.9rem" }}>{projectData[0]?.Actual_Hours__c || 0}</h4>
                                        </div>
                                        <div className='centerdueDateDiv1'>
                                            <h6 style={{ fontSize: "0.8rem" }}>Due:</h6>
                                            <h4 style={{ color: "", fontSize: '0.8rem' }}>{formatDateTime(projectData[0]?.CreatedDate || null)}</h4>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
